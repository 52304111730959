import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setSmartMenu, fetchApplication, fetchApplications, fetchSeeker, fetchAppointments, fetchComments, fetchMe } from '../redux/actions';
import Seeker from '../components/seeker';
import { useParams } from 'react-router-dom';

const Candidate = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();

  const seeker = useSelector((state)=>state.seeker);
  const appointments = useSelector((state)=>state.appointments);
  const appointmentsCount = useSelector((state)=>state.appointmentsCount);
  const applications = useSelector((state)=>state.applications);
  const application = useSelector((state)=>state.application);
  const applicationsCount = useSelector((state)=>state.applicationsCount);
  const comments = useSelector((state)=>state.comments);
  const commentsCount = useSelector((state)=>state.commentsCount);
  const [ component, setComponent ] = useState('joblist');

  useEffect(()=>{
    const { candidateId, applicationId } = params
    if(candidateId && applicationId){
      dispatch(fetchApplication(applicationId))
      dispatch(fetchApplications({seekerId: candidateId}, 5, 0))
      dispatch(fetchAppointments(applicationId))
      dispatch(fetchComments(candidateId, 10, 0))
      dispatch(fetchSeeker(candidateId))
      dispatch(fetchMe())
      setComponent('seeker')
    }
  },[props])

  useEffect(()=>{
    if(seeker)
      dispatch(setSmartMenu({
        headline: `${seeker.givenname} ${seeker.surname}`,
        buttons: [
          { key: 'mail', name: 'button-mail', icon: 'mail', onButtonClick: ()=>{window.location.href=`mailto:${seeker.mail}`}},
          { key: 'call', name: 'button-call', icon: 'call', onButtonClick: ()=>{window.location.href=`tel:${seeker.phone}`}}
        ],
        navigation: [
          { key: 'seeker', name: 'button-seeker', icon: 'copy', onMenuClick: ()=>{setComponent('seeker')} },
          { key: 'application', name: 'button-application', icon: 'archive', onMenuClick: ()=>{setComponent('application')} }
        ]
      }))
  },[seeker])

  return (
    <article>
      <Seeker
        show={true}
        seeker={seeker}
        appointments={appointments}
        appointmentsCount={appointmentsCount}
        application={application}
        applications={applications}
        applicationsCount={applicationsCount}
        comments={comments}
        commentsCount={commentsCount}
        component={component}
      />
    </article>
  );
}

export default Candidate;