import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Transition, Image, Segment, List, Button } from 'semantic-ui-react';
import moment from 'moment'


const Messages = ({ show, messages, onRemove }) => {
  const {t} = useTranslation()

  if(!messages || !show)
    return null

  return (
    <Grid textAlign='left'>
      <Grid.Column textAlign='left'>
        <Segment basic padded='very'>
          <Transition.Group
            as={List}
            duration={500}
            divided
            size='huge'
            verticalAlign='middle'
          >
            {messages.map((item, index) => (
              <List.Item key={`notification-me-#${index}`}>
                <List.Icon name='info' />
                <List.Content>
                  <List.Header as='a'>{item.subject}</List.Header>
                  <List.Description>
                    {`${item.message} (${moment(item.createdAt).fromNow()})`}
                  </List.Description>
                  <List.Description>
                    <Button style={{opacity: '0.3'}} circular icon='trash alternate outline' onClick={()=>onRemove(item.notificationId)} />
                  </List.Description>
                </List.Content>
              </List.Item>
            ))}
          </Transition.Group>
        </Segment>
      </Grid.Column>
    </Grid>
  )
}

export default Messages;