import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useModal } from '../hooks/useModal';
import { setSmartMenu, fetchUsers, fetchMe, updateSmartMenu } from '../redux/actions';
import NewMember from '../components/modal/newMember';
import { UsersList } from '../components';
import { useNavigate } from 'react-router-dom';

const Members = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const me = useSelector((state)=>state.me);
  const users = useSelector((state)=>state.users);
  const usersCount = useSelector((state)=>state.usersCount);

  const [ filter, setFilter ] = useState();
  const [ pagination, setPagination ] = useState({defaultActivePage: 1, totalPages: 1, activePage: 1 })
  const itemsPerPage = 15

  const {show : showA, hide: hideA, RenderModal: RenderModalA} = useModal();

  useEffect(()=>{
    dispatch(fetchUsers(filter, itemsPerPage, 0))
    dispatch(fetchMe())
    dispatch(setSmartMenu({
      buttons: [
        { key: 'add_user', name: 'button-add_user', icon: 'add', onButtonClick: ()=>showA(true)}
      ],
      navigation: null
    }))
  },[props])

  useEffect(()=>{
    if(me)
      dispatch(updateSmartMenu({
        headline: t('headline-welcome', {givenname: me.givenname})
      }))
  },[me])

  useEffect(()=>{
    setPagination(prevData => ({
      ...prevData,
      totalPages: Math.ceil(usersCount / itemsPerPage)
    }))
  },[users])

  const handleOnClickUser = async (target) => {
    navigate(`/members/${target.guid}`)
  }

  const handleOnHideModal = async () => {
    dispatch(fetchUsers(filter, itemsPerPage, 0))
    setPagination(prevData => ({
      ...prevData,
      activePage: 1,
    }))
    hideA()
  }

  const handleOnSetPagination = (e, { activePage }) => {
    let multi = itemsPerPage * (activePage -1)
    if(activePage === 1)
      multi = 0
    dispatch(fetchUsers(filter, itemsPerPage, multi ))
    setPagination(prevData => ({
      ...prevData,
      activePage: activePage,
      totalPages: Math.ceil(usersCount / itemsPerPage)
    }))
  }

  return (
    <article>
      <UsersList
        show={true}
        users={users}
        pagination={pagination}
        onSetPagination={handleOnSetPagination}
        onClickEdit={handleOnClickUser}
      />
      <RenderModalA>
        <NewMember
          hide={handleOnHideModal}
        />
      </RenderModalA>
    </article>
  );
}

export default Members;