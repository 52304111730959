import React from 'react';
import { Grid, Segment, Form, Header } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const Basic = ({show, givenname, surname, streetAddress, postalCode, location, mail, phone}) => {
  const { t } = useTranslation();

  const style = {
    wordBreak: {
      wordBreak: "break-word"
    },
    uppercase: {
      textTransform: "uppercase"
    }
  }

  if(!show)
    return null

  return(
    <Segment basic color='blue' padded='very' textAlign='left'>
      <Header as='h3'>
        {t(`headline-seeker_basic`)}
      </Header>
      <Grid textAlign='left' columns={2}>
        <Grid.Row>
          <Grid.Column>
            <Header as='h4' style={{...style.uppercase}}>
              {t('label-givenname')}
              <Header.Subheader style={{...style.wordBreak, ...style.uppercase}}>
                {givenname}
              </Header.Subheader>
            </Header>
          </Grid.Column>
          <Grid.Column>
            <Header as='h4' style={{...style.uppercase}}>
              {t('label-surname')}
              <Header.Subheader style={{...style.wordBreak, ...style.uppercase}}>
                {surname}
              </Header.Subheader>
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Header as='h4' style={{...style.uppercase}}>
              {t('label-streetAddress')}
              <Header.Subheader style={{...style.wordBreak, ...style.uppercase}}>
                {streetAddress}
              </Header.Subheader>
            </Header>
          </Grid.Column>
          <Grid.Column>
            <Header as='h4' style={{...style.uppercase}}>
              {t('label-location')}
              <Header.Subheader style={{...style.wordBreak, ...style.uppercase}}>
                {`${postalCode} ${location}`}
              </Header.Subheader>
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Header as='h4' style={{...style.uppercase}}>
            {t('label-mail')}
              <Header.Subheader style={{...style.wordBreak, ...style.uppercase}}>
                {mail}
              </Header.Subheader>
            </Header>
          </Grid.Column>
          <Grid.Column>
            <Header as='h4' style={{...style.uppercase}}>
              {t('label-phone')}
              <Header.Subheader style={{...style.wordBreak, ...style.uppercase}}>
                {phone}
              </Header.Subheader>
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )
}

export default Basic