import React from 'react';
import { Grid, Segment, Image, Header, Button, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import user_image from './images/defaultUser.jpg';

const User = ({show, user, onClickDelete}) => {
  const { t } = useTranslation();

  const style = {
    wordBreak: {
      wordBreak: "break-word"
    },
    uppercase: {
      textTransform: "uppercase"
    }
  }

  if(!show || !user)
    return null

  return(
    <section>
      <div>
        <Grid columns='equal' stackable >
          <Grid.Column textAlign='center'>
            <Segment basic padded='very'>
              <div>
                <Image centered src={user_image} size='small' circular alt='application_seeker'/>
              </div>
            </Segment>
          </Grid.Column>
          <Grid.Column  width={10}>
            <Segment basic color='blue' padded='very' textAlign='left'>
              <Header as='h3'>
                {t(`headline-user_basic`)}
              </Header>
              <Grid textAlign='left' columns={2}>
                <Grid.Row>
                  <Grid.Column>
                    <Header as='h4' style={{...style.uppercase}}>
                      {t('label-givenname')}
                      <Header.Subheader style={{...style.wordBreak, ...style.uppercase}}>
                        {user.givenname}
                      </Header.Subheader>
                    </Header>
                  </Grid.Column>
                  <Grid.Column>
                    <Header as='h4' style={{...style.uppercase}}>
                      {t('label-surname')}
                      <Header.Subheader style={{...style.wordBreak, ...style.uppercase}}>
                        {user.surname}
                      </Header.Subheader>
                    </Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Header as='h4' style={{...style.uppercase}}>
                      {t('label-mail')}
                      <Header.Subheader style={{...style.wordBreak, ...style.uppercase}}>
                        {user.mail}
                      </Header.Subheader>
                    </Header>
                  </Grid.Column>
                  <Grid.Column>
                    <Header as='h4' style={{...style.uppercase}}>
                      {t('label-phone')}
                      <Header.Subheader style={{...style.wordBreak, ...style.uppercase}}>
                        {user.phone}
                      </Header.Subheader>
                    </Header>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
            <Segment basic color='blue' padded='very' textAlign='left'>
              <Header as='h3'>
                {t(`headline-user_extension`)}
              </Header>
              <Grid textAlign='left' columns={1}>
                <Grid.Row>
                  <Grid.Column>
                    <Header as='h4' style={{...style.uppercase}}>
                      {t('label-guid')}
                      <Header.Subheader style={{...style.wordBreak, ...style.uppercase}}>
                        {user.guid}
                      </Header.Subheader>
                    </Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Header as='h4' style={{...style.uppercase}}>
                      {t('label-permission')}
                      <Header.Subheader style={{...style.wordBreak, ...style.uppercase}}>
                        {user.permission}
                      </Header.Subheader>
                    </Header>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
            <Button key={`button-user-profile-delete`} floated='right' primary size='medium' onClick={()=>onClickDelete({guid: user.guid})} ><Icon name="remove user" />{t('button-remove_user')}</Button>
          </Grid.Column>
        </Grid>
      </div>
    </section>
  )
}

export default User