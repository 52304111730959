import { NETWORK_ERROR, NETWORK_START, NETWORK_STOP, LOADING_START, LOADING_STOP, SMART_UPDATE, SMART_SET, LOCATIONS_FETCH, TOGGLE_SIDEBAR, PORTAL_HIDE, PORTAL_SHOW, APPLICATIONS_ORDERBY, JOBS_ORDERBY, JOBS_FILTER, APPLICATIONS_PAGINATION, JOBS_PAGINATION } from '../constants/action-types'
import { JOBS_FETCH, JOB_FETCH } from "../constants/action-types";
import { APPLICATIONS_FETCH, APPLICATION_FETCH, APPOINTMENTS_FETCH, APPLICATIONS_FILTER } from "../constants/action-types";
import { SEEKER_FETCH, COMMENTS_FETCH } from "../constants/action-types";
import { USERS_FETCH, USER_FETCH, NOTIFICATION_FETCH, ME_FETCH } from "../constants/action-types";
import { CONFIG_APPLICATIONS_STATE } from "../constants/action-types";

import headerMenu from  '../../config/headerMenu.json'

const initialState = {
  config_applications_state: [],
  loading: false,
  portal: null,
  sidebar: false,
  networkRequests: 0,
  lastError: null,
  headerMenu: headerMenu,
  smartMenu: null,
  locations: null,
  me: null,
  user: null,
  users: [],
  usersCount: null,
  notifications: [],
  job: null,
  jobs: [],
  jobsCount: null,
  jobsOrderBy: 'createdAt',
  jobsFilter: null,
  jobsPagination: {defaultActivePage: 1, totalPages: 1, activePage: 1 },
  application: null,
  applications: [],
  applicationsCount: null,
  applicationsOrderBy: 'createdAt',
  applicationsFilter: null,
  applicationsPagination: {defaultActivePage: 1, totalPages: 1, activePage: 1 },
  appointments: [],
  appointmentsCount: null,
  comments: [],
  commentsCount: null,
  seeker: null,
};

function appReducer(state = initialState, action) {
  switch (action.type) {
    case CONFIG_APPLICATIONS_STATE:
      return { ...state, config_applications_state: action.result };
    case JOBS_ORDERBY:
      return { ...state, jobsOrderBy: action.value };
    case JOBS_FILTER:
      return { ...state, jobsFilter: action.value };
    case JOBS_PAGINATION:
      return { ...state, jobsPagination: action.value };
    case APPLICATIONS_ORDERBY:
      return { ...state, applicationsOrderBy: action.value };
    case APPLICATIONS_FILTER:
      return { ...state, applicationsFilter: action.value };
    case APPLICATIONS_PAGINATION:
      return { ...state, applicationsPagination: action.value };
    case NETWORK_ERROR:
      return { ...state, lastError: action.error };
    case NETWORK_START:
      return { ...state, networkRequests: state.networkRequests + 1 };
    case NETWORK_STOP:
      return { ...state, networkRequests: state.networkRequests - 1 };
    case LOADING_START:
      return { ...state, loading: true };
    case LOADING_STOP:
      return { ...state, loading: false };
    case TOGGLE_SIDEBAR:
      return { ...state, sidebar: !state.sidebar };
    case PORTAL_SHOW:
      return { ...state, portal: action.result};
    case PORTAL_HIDE:
      return { ...state, portal: null};
    case SMART_SET:
      return { ...state, smartMenu : action.smartMenu};
    case SMART_UPDATE:
      let update = action.smartMenu
      let item = {
        ...state.smartMenu,
        ...update
      }
      return { ...state, smartMenu : item};
    case LOCATIONS_FETCH:
      return { ...state, locations : action.result};
    case JOBS_FETCH:
      return { ...state, jobs: action.result, jobsCount: action.count };
    case JOB_FETCH:
      return { ...state, job: action.result};
    case ME_FETCH:
      return { ...state, me: action.result};
    case USER_FETCH:
      return { ...state, user: action.result};
    case USERS_FETCH:
      return { ...state, users: action.result, usersCount: action.count };
    case APPLICATIONS_FETCH:
      return { ...state, applications: action.result, applicationsCount: action.count };
    case APPLICATION_FETCH:
      return { ...state, application : action.result }
    case APPOINTMENTS_FETCH:
      return { ...state, appointments: action.result, appointmentsCount: action.count };
    case COMMENTS_FETCH:
      return { ...state, comments: action.result, commentsCount: action.count };
    case NOTIFICATION_FETCH:
      return { ...state, notifications: action.result };
    case SEEKER_FETCH:
      return { ...state, seeker: action.result };
    default:
      return state;
  }
}

export default appReducer;