import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "../reducers";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from "redux-thunk";
import apiMiddleware from "../middleware/notificationMiddleware";

const persistConfig = {
  key: 'bhmm-hr-storage',
  storage,
  blacklist: ['sidebar', 'loading', 'networkRequests', 'applicationsFilter', 'applicationsPagination', 'jobsFilter', 'jobsPagination']
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

const middlewareEnhancer = applyMiddleware(thunk, apiMiddleware)
const storeEnhancers = compose(middlewareEnhancer);

let store = createStore(persistedReducer, storeEnhancers)
let persistor = persistStore(store)

export {
  store, 
  persistor
}