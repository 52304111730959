import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'
import { Modal, Form, Button, Icon } from 'semantic-ui-react';
import jobConfig from '../../config/job.json';
import { useSelector } from 'react-redux';
import { insert_job } from '../../utils/requestHelper';

function removeEmptyStrings(obj){
  let newObj = {};
  Object.keys(obj).forEach((prop) => {
    if (obj[prop] !== null && obj[prop] !== '') { newObj[prop] = obj[prop] }
    if (obj[prop] === 0) { newObj[prop] = obj[prop] }
  })
  return newObj;
};

const NewVacancie = ({hide}) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const locations = useSelector((state)=>state.locations)
  const [ data, setData ] = useState()
  const [ errors, setErrors ] = useState({})

  const locationOptions = locations.map((lo => ({
    key: lo.id, id: 'locationId', value: lo.id, text: lo.name
  })))

  const handleOnChange = (e) => {
    const { id, value } = e.target
    setData(prevData => ({
        ...prevData,
        [id]: value
    }))
  }

  const handleOnSelectChange = (e, { value, id }) => {
    setData(prevData => ({
      ...prevData,
      [id]: value
    }))
  }

  const handleOnSubmit = async () => {
    if(data) {
      let obj = removeEmptyStrings(data)
      let newErrors = {}
      let error = false
      if(!obj.hasOwnProperty('name')) {
        newErrors.nameError = t('input-error-name')
        error = true
      }
      if(!obj.hasOwnProperty('identifier')) {
        newErrors.identifierError = t('input-error-identifier')
        error = true
      }
      if(!obj.hasOwnProperty('department')) {
        newErrors.departmentError = t('input-error-department')
        error = true
      }
      if(!obj.hasOwnProperty('locationId')) {
        newErrors.locationError = t('input-error-location')
        error = true
      }
      if(!obj.hasOwnProperty('subscription')) {
        newErrors.subscriptionError = t('input-error-subscription')
        error = true
      }
      if(!obj.hasOwnProperty('entryLevel')) {
        newErrors.entryLevelError = t('input-error-entryLevel')
        error = true
      }
      if(!obj.hasOwnProperty('description')) {
        newErrors.descriptionError = t('input-error-description')
        error = true
      }
    
      setErrors({
        ...newErrors
      })
      if(error === false){
        var result = await insert_job(obj)
        if(result.success === true)
          hide()
          navigate(`/jobs`)
      }
    }
  }

  return(
    <>
      <Modal.Header>{t(`headline-new_job`)}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form>
            <Form.Input id='name' error={errors.hasOwnProperty('nameError') && { content: errors.nameError }} onChange={handleOnChange} required label={t(`input-label-name`)} placeholder={t(`input-placeholder-name`)} />
            <Form.Group widths='equal'>
              <Form.Input id='identifier' error={errors.hasOwnProperty('identifierError') && { content: errors.identifierError }} onChange={handleOnChange} required label={t(`input-label-identifier`)} placeholder={t(`input-placeholder-identifier`)} />
              <Form.Input id='department' error={errors.hasOwnProperty('departmentError') && { content: errors.departmentError }} onChange={handleOnChange} required label={t(`input-label-department`)} placeholder={t(`input-placeholder-department`)} />
            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Select
                fluid
                required
                id='locationId'
                label={t(`input-label-location`)}
                options={locationOptions}
                placeholder={t(`input-placeholder-location`)}
                onChange={handleOnSelectChange}
                error={errors.hasOwnProperty('locationError') && { content: errors.locationError }}
              />
              <Form.Select
                fluid
                required
                id='subscription'
                label={t(`input-label-subscription`)}
                options={jobConfig.subscription}
                placeholder={t(`input-placeholder-subscription`)}
                onChange={handleOnSelectChange}
                error={errors.hasOwnProperty('subscriptionError') && { content: errors.subscriptionError }}
              />
              <Form.Select
                fluid
                required
                id='entryLevel'
                label={t(`input-label-entryLevel`)}
                options={jobConfig.entryLevel}
                placeholder={t(`input-placeholder-entryLevel`)}
                onChange={handleOnSelectChange}
                error={errors.hasOwnProperty('entryLevelError') && { content: errors.entryLevelError }}
              />
            </Form.Group>
            <Form.TextArea required rows={12} id='description' error={errors.hasOwnProperty('descriptionError') && { content: errors.descriptionError }} onChange={handleOnChange} label={t(`input-label-description`)} placeholder={t(`input-label-description`)} />
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={hide}>
          <Icon name='times' />
          {t(`button-cancel`)}
        </Button>
        <Button onClick={handleOnSubmit} primary>
          <Icon name='check' />
          {t(`button-create`)}
        </Button>
      </Modal.Actions>
    </>
  )
}

export default NewVacancie