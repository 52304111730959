import { useEffect } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { check_authentication } from '../../utils/requestHelper';
import { setAccessToken, setRefreshToken } from '../../utils/common';

const Authentication = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(()=> {
    const check = async (accessToken, refreshToken) => {
      let res = await check_authentication(accessToken)
      if(res === true) {
        setAccessToken(accessToken)
        setRefreshToken(refreshToken)
        navigate('/applications')
      }
      else {
        window.location.href = `${window._env_.REACT_APP_AUTH_URL}?redirectURL=${window._env_.REACT_APP_HR_URL}/authentication`;
      }
    }
    if(location.search) {
      const accessToken = searchParams.get("accessToken")
      const refreshToken = searchParams.get("refreshToken")
      if(accessToken && refreshToken){
        check(accessToken, refreshToken)
      }
      else {
        window.location.href = `${window._env_.REACT_APP_AUTH_URL}?redirectURL=${window._env_.REACT_APP_HR_URL}/authentication`;
      }
    }
    // eslint-disable-next-line
  },[location])
  return null
}

export default Authentication