import { ME_FETCH, CONFIG_APPLICATIONS_STATE, NOTIFICATION_FETCH, NETWORK_START, NETWORK_ERROR, NETWORK_STOP } from "../constants/action-types";
import { fetch_notifications, fetch_config_applications_state } from "../../utils/requestHelper";

var isStarted

const apiMiddleware = ({ getState, dispatch }) => next => async action => {
  if(action.type == ME_FETCH && isStarted != true){
      function startTimer() {
        setTimeout(()=> {
          asyncFunction()
          startTimer()
          isStarted = true
        }, 30000);
      }
      const asyncFunction = async () => {
        try {
          dispatch({ type: NETWORK_START });
          const result = await fetch_notifications()
          if(result.success === true){
            dispatch({ type: NOTIFICATION_FETCH, result: result.data })
          }
          dispatch({ type: NETWORK_STOP });
        } catch (error) {
          dispatch({ type: NETWORK_STOP });
          dispatch({ type: NETWORK_ERROR, error });
        }
      }
      const loadConfig = async () => {
        try {
          dispatch({ type: NETWORK_START });
          const result = await fetch_config_applications_state()
          if(result.success === true){
            dispatch({ type: CONFIG_APPLICATIONS_STATE, result: result.data })
          }
          dispatch({ type: NETWORK_STOP });
        } catch (error) {
          dispatch({ type: NETWORK_STOP });
          dispatch({ type: NETWORK_ERROR, error });
        }
      }
      loadConfig()
      startTimer()
  }
  next(action);
}

export default apiMiddleware