import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setSmartMenu, fetchJob, showPortal, fetchMe, fetchApplications, updateJob, setApplicationsOrderBy } from '../redux/actions';
import { Vacancie } from '../components';
import { fetch_applications, fetch_job, insert_job, fetch_job_statistics, fetch_job_application_statistics } from '../utils/requestHelper';
import { useNavigate, useParams } from 'react-router-dom';

const Job = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const [ component, setComponent ] = useState('joblist');
  const [ impressions, setImpressions ] = useState();
  const [ applicationSTatistics, setApplicationStatistics ] = useState();
  const [ urlJobId, setUrlJobId ] = useState();
  const job = useSelector((state)=>state.job);
  const applications = useSelector((state)=>state.applications);
  const applicationsCount = useSelector((state)=>state.applicationsCount);
  const applicationsOrderBy = useSelector((state)=>state.applicationsOrderBy);

  const [ pagination, setPagination ] = useState({defaultActivePage: 1, totalPages: 1, activePage: 1 })
  const itemsPerPage = 15

  useEffect(()=>{
    const { jobId } = params
    if(jobId){
      dispatch(fetchJob(jobId))
      dispatch(fetchApplications({jobId:jobId}, applicationsOrderBy, itemsPerPage, 0))
      dispatch(fetchMe())
      setUrlJobId(jobId)
      setComponent('applications')
    }
  },[props])

  useEffect(()=>{
    const fetchData = async(jobId) => {
      let resultJobImpressions = await fetch_job_statistics(jobId)
      let resultJobStatistics = await fetch_job_application_statistics(jobId)
      if(resultJobImpressions.success)
        setImpressions(resultJobImpressions.data)
      if(resultJobStatistics.success)
        setApplicationStatistics(resultJobStatistics.data)
    }
    if(job && component === 'statistics'){
      fetchData(job.jobId)
    }
  },[component])

  useEffect(()=>{
    if(job) {
      var buttons = [{ key: 'copy', name: 'button-duplicate', icon: 'copy', onButtonClick: ()=>handleOnCopyJob(job.jobId)}]
      if(job.visibility === 1)
        buttons.push({ key: 'redo', name: 'button-redo', icon: 'redo', onButtonClick: ()=>handleOnRedoJob(job.jobId)})
      else
        buttons.push({ key: 'archive', name: 'button-archive', icon: 'archive', onButtonClick: ()=>handleOnToArchive(job.jobId)})
      dispatch(setSmartMenu({
        headline: job.name,
        buttons: buttons,
        navigation: [
          { key: 'applications', name: 'button-applications', icon: 'copy', onMenuClick: ()=>{setComponent('applications')} },
          { key: 'statistics', name: 'button-statistics', icon: 'archive', onMenuClick: ()=>{setComponent('statistics')} },
          { key: 'edit', name: 'button-edit', icon: 'archive', onMenuClick: ()=>{setComponent('edit')} },
          { key: 'question', name: 'button-question', icon: 'archive', onMenuClick: ()=>{setComponent('question')} },
          { key: 'preview', name: 'button-preview', icon: 'archive', onMenuClick: ()=>{setComponent('preview')} }
        ]
      }))
    }
  },[job])

  useEffect(()=>{
    if(urlJobId) {
      var skip = pagination.activePage
      if(skip === 1)
        skip = 0
      dispatch(fetchApplications({jobId:urlJobId}, applicationsOrderBy, itemsPerPage, skip ))
    }
  },[applicationsOrderBy])

  const handleOnCopyJob = async(jobId) => {
    var element = await fetch_job(jobId)
    if(element.success === true){
      var result = await insert_job(element.data)
      if(result.success === true)
        navigate(`/jobs`)
    }
  }

  const handleOnRedoJob = async(jobId) => {
    dispatch(updateJob(jobId, { visibility: 2, isActive: 0}))
    dispatch(showPortal({header:t('headline-redo_job'),message:t('content-redo_job'),error:true}))
  }

  const handleOnToArchive = async (jobId) => {
    var elements = await fetch_applications({jobId:jobId})
    if(elements) {
      let open = elements.data.filter(el => { return (el.status === 2 || el.status === 3 || el.status === 4) })
      if(open.length > 0)
        dispatch(showPortal({header:t('headline-error_archive_job'),message:t('content-error_archive_job'),error:true}))
      else {
        dispatch(updateJob(jobId, { visibility: 1, isActive: 0}))
        dispatch(showPortal({header:t('headline-archive_job'),message:t('content-archive_job'),error:true}))
      }
    }
  }

  const handleOnClickSeekerDetails = async (target) => {
    navigate(`/applications/${target.applicationId}/candidates/${target.seekerId}`)
  }

  const handleOnSetPagination = (e, { activePage }) => {
    let multi = itemsPerPage * (activePage -1)
    if(activePage === 1)
      multi = 0
    dispatch(fetchApplications({jobId:urlJobId}, applicationsOrderBy, itemsPerPage, multi ))
    setPagination(prevData => ({
      ...prevData,
      activePage: activePage,
      totalPages: Math.ceil(applicationsCount / itemsPerPage)
    }))
  }

  const handleOnChangeOrderBy = (e, { value }) => {
    dispatch(setApplicationsOrderBy(value))
  }

  return (
    <article>
      <Vacancie
        show={true}
        vacancie={job}
        impressions={impressions}
        statistics={applicationSTatistics}
        component={component}
        onClickSeeker={handleOnClickSeekerDetails}
        applications={applications}
        applicationsCount={applicationsCount}
        applicationsOrderBy={applicationsOrderBy}
        applicationsPagination={pagination}
        applicationsOnSetPagination={handleOnSetPagination}
        applicationsOnChangeOrderBy={handleOnChangeOrderBy}
      />
    </article>
  );
}

export default Job;