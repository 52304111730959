import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Button, Form, Segment } from 'semantic-ui-react';


const Basic = ({ show, user, onSubmit }) => {
  const {t} = useTranslation()
  const [ values, setValues ] = useState()

  if(!user || !show)
    return null

  const handleOnChange = (e) => {
    const { id, value } = e.target
    setValues({
      ...values,
      [id]: value
    })
  }

  const handleSubmit = () => {
    onSubmit(values)
    setValues(null)
  }
    
  return (
    <Grid textAlign='left'>
      <Grid.Column textAlign='left'>
        <Segment basic padded='very'>
          <Form onSubmit={handleSubmit}>
            <Form.Field>
              <label>{t('label-givenname')}</label>
              <Form.Input id='givenname' placeholder={t('label-givenname')} defaultValue={user.givenname} onChange={handleOnChange}/>
            </Form.Field>
            <Form.Field>
              <label>{t('label-surname')}</label>
              <Form.Input id='surname' placeholder={t('label-surname')} defaultValue={user.surname} onChange={handleOnChange}/>
            </Form.Field>
            <Form.Field>
              <label>{t('label-phone')}</label>
              <Form.Input id='phone' placeholder={t('label-phone')} defaultValue={user.phone} onChange={handleOnChange}/>
            </Form.Field>
            <Button primary type='submit'>{t('button-save')}</Button>
          </Form>
        </Segment>
      </Grid.Column>
    </Grid>
  )
}

export default Basic;