import { USERS_FETCH, USER_FETCH, ME_FETCH, NETWORK_START, NETWORK_ERROR, NETWORK_STOP, PORTAL_SHOW } from "../constants/action-types";
import { fetch_users, fetch_me, update_me, fetch_user } from "../../utils/requestHelper";

export function fetchUsers(filter, limit, skip){
  return async (dispatch) => {
    try {
      dispatch({ type: NETWORK_START });
      const result = await fetch_users(filter, limit, skip)
      if(result.success === true){
        dispatch({ type: USERS_FETCH, result: result.data, count: result.count })
      }
      dispatch({ type: NETWORK_STOP });
    } catch (error) {
      dispatch({ type: NETWORK_STOP });
      dispatch({ type: NETWORK_ERROR, error });
    }
  };
}

export function fetchUser(guid){
  return async (dispatch) => {
    try {
      dispatch({ type: NETWORK_START });
      const result = await fetch_user(guid)
      if(result.success === true){
        dispatch({ type: USER_FETCH, result: result.data })
      }
      dispatch({ type: NETWORK_STOP });
    } catch (error) {
      dispatch({ type: NETWORK_STOP });
      dispatch({ type: NETWORK_ERROR, error });
    }
  };
}

export function fetchMe(){
  return async (dispatch) => {
    try {
      dispatch({ type: NETWORK_START });
      const result = await fetch_me()
      if(result.success === true){
        dispatch({ type: ME_FETCH, result: result.data })
      }
      dispatch({ type: NETWORK_STOP });
    } catch (error) {
      dispatch({ type: NETWORK_STOP });
      dispatch({ type: NETWORK_ERROR, error });
    }
  };
}

export function updateMe(values){
  return async (dispatch) => {
    try {
      dispatch({ type: NETWORK_START });
      const result = await update_me(values)
      if(result.success === true){
        dispatch({ type: ME_FETCH, result: result.data })
        dispatch({type: PORTAL_SHOW, result: {header:'Benutzer geändert',message:'Benutzerdaten erfolgreich geändert',error:false}})
      }
      dispatch({ type: NETWORK_STOP });
    } catch (error) {
      dispatch({ type: NETWORK_STOP });
      dispatch({ type: NETWORK_ERROR, error });
    }
  };
}