import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Segment, Header, Modal, Button, Form, Checkbox, Icon, Dropdown, Image } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import jobConfig from '../../config/job.json';
import { delete_jobActivitie, delete_jobSkill, insert_jobAktivitie, insert_jobSkill } from '../../utils/requestHelper';
import { fetchJob, updateJob } from '../../redux/actions';
import { useModal } from '../../hooks/useModal';

import defaultUser from './images/defaultUser.jpg'

const VacanciesItem = ({data, show}) => {
  const { t } = useTranslation();
  const [ update, setData ] = useState();
  const dispatch = useDispatch();
  const locations = useSelector((state)=>state.locations);
  const users = useSelector((state)=>state.users);
  const {show : showA, hide: hideA, RenderModal: RenderModalA} = useModal();
  const {show : showB, hide: hideB, RenderModal: RenderModalB} = useModal();
  const {show : showC, hide: hideC, RenderModal: RenderModalC} = useModal();

  useEffect(()=>{
    setData(null)
  },[show])

  if(!show)
    return null

  const locationOptions = locations.map((lo => ({
    key: lo.id, value: lo.id, text: lo.name
  })))

  const userOptions = users.map((lo => ({
    key: lo.guid, value: lo.guid, text: `${lo.givenname} ${lo.surname}`, image:  { avatar: true, src: defaultUser }
  })))

  const handleOnChange = (e) => {
    const { id, value } = e.target
    setData(prevData => ({
        ...prevData,
        [id]: value
    }))
  }

  const handleOnRemoveItem = async (e, { id }) => {
    if(e==='skill')
      await delete_jobSkill(data.jobId, id)
    if(e==='activitie')
      await delete_jobActivitie(data.jobId, id)
    dispatch(fetchJob(data.jobId))
  }

  const handleOnAddItem = async (e, { name }) => {
    if(e==='skill')
      await insert_jobSkill(data.jobId, name)
    if(e==='activitie')
      await insert_jobAktivitie(data.jobId, name)
    dispatch(fetchJob(data.jobId))
  }

  const handleOnChangeOrganizer = async (values) => {
    dispatch(updateJob(data.jobId, values))
  }

  const handleOnSubmit = () => {
    dispatch(updateJob(data.jobId, update))
    setData(null)
  }

  const handleOnValueChange = (e, { value, id }) => {
    dispatch(updateJob(data.jobId, {[id]:value}))
  }

  const handleOnChangeState = (e, { checked }) => {
    if(checked === true)
      dispatch(updateJob(data.jobId, {isActive:1}))
    if(checked === false)
      dispatch(updateJob(data.jobId, {isActive:0}))
  }

  return(
    <div>
      <Grid columns='equal' stackable >
        <Grid.Column width={10}>
          <Segment basic color='blue' padded='very'>
            <Header as='h3'>
              {t(`headline-information`)}
            </Header>
            <Form >
              <Form.Input id='name' onChange={handleOnChange} defaultValue={data.name}  label={t(`input-label-name`)} placeholder={t(`input-placeholder-name`)} />
              <Form.Group widths='equal'>
                <Form.Input id='identifier' onChange={handleOnChange} defaultValue={data.identifier} label={t(`input-label-identifier`)} placeholder={t(`input-placeholder-identifier`)} />
                <Form.Input id='department' onChange={handleOnChange} defaultValue={data.department} label={t(`input-label-department`)} placeholder={t(`input-placeholder-department`)} />
              </Form.Group>
              <Form.TextArea rows={12} onChange={handleOnChange} id='description' defaultValue={data.description} label={t(`input-label-description`)} placeholder={t(`input-placeholder-description`)} />
              <Button primary onClick={handleOnSubmit}>
                <Icon name='check' />
                {t(`button-save`)}
              </Button>
            </Form>
          </Segment>
          <Segment basic color='blue' padded='very'>
            <Grid columns={2}>
              <Grid.Column>
                <Header as='h3'>
                {t(`headline-skills`)}
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Button primary animated='vertical' floated='right' onClick={()=>showA(true)}>
                  <Button.Content hidden>{t(`button-new`)}</Button.Content>
                  <Button.Content visible>
                    <Icon name='add' />
                  </Button.Content>
                </Button>
              </Grid.Column>
            </Grid>
            <Grid textAlign='left' divided='vertically'>
              {data.skills.map((element, index) => (
                renderSkills(index, handleOnRemoveItem, element)
              ))}
            </Grid>
          </Segment>
          <Segment basic color='blue' padded='very'>
            <Grid columns={2}>
              <Grid.Column>
                <Header as='h3'>
                {t(`headline-activities`)}
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Button primary animated='vertical' floated='right' onClick={()=>showB(true)}>
                  <Button.Content hidden>{t(`button-new`)}</Button.Content>
                  <Button.Content visible>
                    <Icon name='add' />
                  </Button.Content>
                </Button>
              </Grid.Column>
            </Grid>
            <Grid textAlign='left' divided='vertically'>
              {data.activities.map((element, index) => (
                renderActivities(index, handleOnRemoveItem, element)
              ))}
            </Grid>
          </Segment>
        </Grid.Column>
        <Grid.Column>
          <Segment basic color='blue' padded='very'>
            <Header as='h3'>
              {t(`headline-visibility`)}
            </Header>
            <Form.Field>
              <Checkbox toggle defaultChecked={data.isActive} label={t(`input-label-isActive`)} onChange={handleOnChangeState} />
            </Form.Field>
          </Segment>
          <Segment basic color='blue' padded='very'>
            <Header as='h3'>
              {t(`headline-details`)}
            </Header>
            <Form.Select
              fluid
              id='locationId'
              label={t(`input-label-location`)}
              defaultValue={data.locationId}
              options={locationOptions}
              placeholder={t(`input-placeholder-location`)}
              onChange={handleOnValueChange}
            />
            <Form.Select
              fluid
              id='subscription'
              label={t(`input-label-subscription`)}
              defaultValue={data.subscription}
              options={jobConfig.subscription}
              placeholder={t(`input-placeholder-subscription`)}
              onChange={handleOnValueChange}
            />
            <Form.Select
              fluid
              id='entryLevel'
              label={t(`input-label-entryLevel`)}
              options={jobConfig.entryLevel}
              defaultValue={data.entryLevel}
              placeholder={t(`input-placeholder-entryLevel`)}
              onChange={handleOnValueChange}
            />
          </Segment>
          <Segment basic color='blue' padded='very'>
            <Header as='h3'>
              {t(`headline-contact`)}
            </Header>
            {data.organizer && <Segment padded='very'>
              <Header as='h2'>
                <Image src={defaultUser} circular />
                <Header.Content>
                  {`${data.organizer.givenname} ${data.organizer.surname}`}
                  <Header.Subheader>{data.organizer.mail}</Header.Subheader>
                </Header.Content>
              </Header>
            </Segment>}
            <Button primary onClick={()=>showC(true)}>
              <Icon name='check' />
              {t(`button-choose`)}
            </Button>
          </Segment>
        </Grid.Column>
      </Grid>
      {modalA(RenderModalA, t, hideA, handleOnAddItem)}
      {modalB(RenderModalB, t, hideB, handleOnAddItem)}
      {modalC(RenderModalC, t, hideC, handleOnChangeOrganizer, userOptions)}
    </div>
  )

}

export default VacanciesItem

function modalC(RenderModalC, t, hideC, handleOnChangeOrganizer, userOptions) {
  let values = {}
  const handleOnChange = (e, { value, id }) => {
    values = {
      ...values,
      [id]: value
    }
  }
  const handleOnSave = () => {
    handleOnChangeOrganizer(values)
    hideC()
  }
  return <RenderModalC>
    <Modal.Header>{t(`headline-contact`)}</Modal.Header>
    <Modal.Content>
      <Modal.Description>
        <p>{t(`content-contact`)}</p>
        <Form>
          <Dropdown
            placeholder={t(`input-label-contact`)}
            id='organizerId'
            fluid
            selection
            options={userOptions}
            onChange={handleOnChange}
          />
        </Form>
      </Modal.Description>
    </Modal.Content>
    <Modal.Actions>
      <Button onClick={hideC}>
        <Icon name='times' />
        {t(`button-cancel`)}
      </Button>
      <Button primary onClick={handleOnSave}>
        <Icon name='check' />
        {t(`button-save`)}
      </Button>
    </Modal.Actions>
  </RenderModalC>;
}

function modalB(RenderModalB, t, hideB, handleOnAddItem) {
  let values = {}
  const handleOnChange = (e) => {
    const { id, value } = e.target
    values = {
      ...values,
      [id]: value
    }
  }
  const handleOnSave = () => {
    handleOnAddItem('activitie', values)
    hideB()
  }
  return <RenderModalB>
    <Modal.Header>{t(`headline-new_activitie`)}</Modal.Header>
    <Modal.Content>
      <Modal.Description>
        <p>{t(`content-new_activitie`)}</p>
        <Form>
          <Form.TextArea onChange={handleOnChange} rows={12} id='name' label={t(`input-label-activitie`)} placeholder={t(`input-placeholder-activitie`)} />
        </Form>
      </Modal.Description>
    </Modal.Content>
    <Modal.Actions>
      <Button onClick={hideB}>
        <Icon name='times' />
        {t(`button-cancel`)}
      </Button>
      <Button primary onClick={handleOnSave}>
        <Icon name='check' />
        {t(`button-save`)}
      </Button>
    </Modal.Actions>
  </RenderModalB>;
}

function modalA(RenderModalA, t, hideA, handleOnAddItem) {
  let values = {}
  const handleOnChange = (e) => {
    const { id, value } = e.target
    values = {
      ...values,
      [id]: value
    }
  }
  const handleOnSave = () => {
    handleOnAddItem('skill', values)
    hideA()
  }
  return <RenderModalA>
    <Modal.Header>{t(`headline-new_skill`)}</Modal.Header>
    <Modal.Content>
      <Modal.Description>
        <p>{t(`content-new_skill`)}</p>
        <Form>
          <Form.TextArea onChange={handleOnChange} rows={12} id='name' label={t(`input-label-skill`)} placeholder={t(`input-placeholder-skill`)} />
        </Form>
      </Modal.Description>
    </Modal.Content>
    <Modal.Actions>
      <Button onClick={hideA}>
        <Icon name='times' />
        {t(`button-cancel`)}
      </Button>
      <Button primary onClick={handleOnSave}>
        <Icon name='check' />
        {t(`button-save`)}
      </Button>
    </Modal.Actions>
  </RenderModalA>;
}

function renderSkills(index, handleOnRemoveItem, element) {
  return <Grid.Row columns={1} key={`vacancies-row-skill-#${index}`}>
    <Grid.Column>
      <Segment basic raised key={`vacancies-item-skill-#${index}`}>
        <Button onClick={() => handleOnRemoveItem('skill', { id: element.id })} floated='right' circular icon='trash alternate' color='red' />
        <p>{element.name}</p>
      </Segment>
    </Grid.Column>
  </Grid.Row>;
}

function renderActivities(index, handleOnRemoveItem, element) {
  return <Grid.Row columns={1} key={`vacancies-row-activities-#${index}`}>
    <Grid.Column>
      <Segment basic raised key={`vacancies-item-activities-#${index}`}>
        <Button onClick={() => handleOnRemoveItem('activitie', { id: element.id })} floated='right' circular icon='trash alternate' color='red' />
        <p>{element.name}</p>
      </Segment>
    </Grid.Column>
  </Grid.Row>;
}
