export const NETWORK_ERROR             = Symbol.for('network.error');
export const NETWORK_START             = Symbol.for('network.start');
export const NETWORK_STOP              = Symbol.for('network.stop');
export const LOADING_START             = Symbol.for('loader.start');
export const LOADING_STOP              = Symbol.for('loader.stop');
export const PORTAL_SHOW               = Symbol.for('portal.show');
export const PORTAL_HIDE               = Symbol.for('portal.hide');
export const TOGGLE_SIDEBAR            = Symbol.for('sidebar.show');
export const LOCATIONS_FETCH           = Symbol.for('location.list');
export const JOBS_FETCH                = Symbol.for('job.list');
export const JOBS_ORDERBY              = Symbol.for('job.orderby');
export const JOBS_FILTER               = Symbol.for('job.filter');
export const JOBS_PAGINATION           = Symbol.for('job.pagination');
export const JOB_FETCH                 = Symbol.for('job.get');
export const APPLICATIONS_FETCH        = Symbol.for('application.list');
export const APPLICATIONS_ORDERBY      = Symbol.for('application.orderby');
export const APPLICATIONS_FILTER       = Symbol.for('application.filter');
export const APPLICATIONS_PAGINATION   = Symbol.for('application.pagination');
export const APPLICATION_FETCH         = Symbol.for('application.get');
export const SEEKER_FETCH              = Symbol.for('seeker.get');
export const SMART_SET                 = Symbol.for('smart.set');
export const SMART_UPDATE              = Symbol.for('smart.update');
export const APPOINTMENTS_FETCH        = Symbol.for('appointments.list');
export const COMMENTS_FETCH            = Symbol.for('comments.list');
export const USERS_FETCH               = Symbol.for('user.list');
export const USER_FETCH                = Symbol.for('user.get');
export const ME_FETCH                  = Symbol.for('me.get')
export const NOTIFICATION_FETCH        = Symbol.for('notification.list');
export const CONFIG_APPLICATIONS_STATE = Symbol.for('config.applications_state');