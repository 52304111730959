import { APPLICATIONS_FETCH, APPLICATIONS_ORDERBY, APPLICATIONS_FILTER, APPLICATIONS_PAGINATION, APPLICATION_FETCH, APPOINTMENTS_FETCH, NETWORK_START, NETWORK_ERROR, NETWORK_STOP } from "../constants/action-types";
import { fetch_applications, fetch_application, fetch_appointments, update_application } from "../../utils/requestHelper";

export function setApplicationsOrderBy(value){
  return (dispatch) => {
    dispatch({ type: APPLICATIONS_ORDERBY, value: value })
  }
}

export function setApplicationsFilter(value){
  return (dispatch) => {
    dispatch({ type: APPLICATIONS_FILTER, value: value })
  }
}

export function setApplicationsPagination(value){
  return (dispatch) => {
    dispatch({ type: APPLICATIONS_PAGINATION, value: value })
  }
}

export function fetchApplications(filter, orderBy, limit, skip){
  return async (dispatch) => {
    try {
      dispatch({ type: NETWORK_START });
      const result = await fetch_applications(filter, orderBy, limit, skip)
      if(result.success === true){
        dispatch({ type: APPLICATIONS_FETCH, result: result.data, count: result.count })
      }
      dispatch({ type: NETWORK_STOP });
    } catch (error) {
      dispatch({ type: NETWORK_STOP });
      dispatch({ type: NETWORK_ERROR, error });
    }
  };
}

export function fetchApplication(applicationId){
  return async (dispatch) => {
    try {
      dispatch({ type: NETWORK_START });
      const result = await fetch_application(applicationId)
      if(result.success === true){
        dispatch({ type: APPLICATION_FETCH, result: result.data })
      }
      dispatch({ type: NETWORK_STOP });
    } catch (error) {
      dispatch({ type: NETWORK_STOP });
      dispatch({ type: NETWORK_ERROR, error });
    }
  };
}

export function fetchAppointments(applicationId, limit, skip){
  return async (dispatch) => {
    try {
      dispatch({ type: NETWORK_START });
      const result = await fetch_appointments(applicationId, limit, skip)
      if(result.success === true){
        dispatch({ type: APPOINTMENTS_FETCH, result: result.data, count: result.count })
      }
      dispatch({ type: NETWORK_STOP });
    } catch (error) {
      dispatch({ type: NETWORK_STOP });
      dispatch({ type: NETWORK_ERROR, error });
    }
  };
}

export function updateApplication(applicationId, data){
  return async (dispatch) => {
    try {
      dispatch({ type: NETWORK_START });
      const result = await update_application(applicationId, data)
      if(result.success === true){
        dispatch({ type: APPLICATION_FETCH, result: result.data })
      }
      dispatch({ type: NETWORK_STOP });
    } catch (error) {
      dispatch({ type: NETWORK_STOP });
      dispatch({ type: NETWORK_ERROR, error });
    }
  };
}