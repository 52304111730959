import React from 'react';
import { Grid, Segment, Header } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const Questions = ({show, questions }) => {
  const { t } = useTranslation();

  if(!show)
    return null

  return(
    <Segment basic color='blue' padded='very' textAlign='left'>
      <Header as='h3'>
        {t(`headline-questions`)}
      </Header>
      <Grid textAlign='left' columns={1}>
        {questions && questions.map((element, index) =>(
          <Grid.Row key={`application-attributes-#${index}`}>
            <Grid.Column>
              <Header
                as='h4'
                content={element.name}
              />
              <p>{element.notes}</p>
            </Grid.Column>
          </Grid.Row>
        ))}
      </Grid>
    </Segment>
  )
}

export default Questions