import React from 'react'
import { useSelector } from "react-redux";
import LoadingImage from './cde820_006769c1d9f54d42819793bd96607859_mv2.webp'
import './Loader.scss'

const Loading = props => {

  const loading = useSelector((state) => state.loading)
  if(loading === false || loading === null)
    return null

  return(
    <div className="loaderCover">
      <img className='laoderSpinner' src={LoadingImage} alt='Backhaus Häussler | Human Resources Management'/>
    </div>
  )
}

export default (Loading);