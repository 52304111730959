import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { verify_token } from './requestHelper';
import { getAccessToken } from './common';

function PrivateRoute({ children, permissions }) {
  const navigate = useNavigate();
  const verifyAccess = async(token) => {
    return new Promise(async (resolve, reject) => {
      const result = await verify_token(token)
      if (result.success === false || !result)
        reject(false)
      if (permissions) {
        if (permissions.includes(result.data.user.permission))
          resolve(true)
        else
          reject(false)
      }
      else
        resolve(true)
    })
  }
  const token = getAccessToken()
  verifyAccess(token).then((data)=> console.log(data)).catch(err => navigate('/'))

  return token ? children : <Navigate to="/" />;
}

export default PrivateRoute;