import { NOTIFICATION_FETCH, NETWORK_START, NETWORK_ERROR, NETWORK_STOP } from "../constants/action-types";
import { fetch_notifications } from "../../utils/requestHelper";

export function fetchNotifications(){
  return async (dispatch) => {
    try {
      dispatch({ type: NETWORK_START });
      const result = await fetch_notifications()
      if(result.success === true){
        dispatch({ type: NOTIFICATION_FETCH, result: result.data })
      }
      dispatch({ type: NETWORK_STOP });
    } catch (error) {
      dispatch({ type: NETWORK_STOP });
      dispatch({ type: NETWORK_ERROR, error });
    }
  };
}