import React from 'react';
import VacanciesItem from './vacanciesItem';
import VacancieQuestions from './vacancieQuestion';
import VacanciesStatistics from './vacanciesStatistics';
import Preview from './preview';
import ApplicationsList from '../applications/applicationsList';

const Vacancie = ({show, component, impressions, statistics, onClickSeeker, vacancie, applications, applicationsCount, applicationsPagination, applicationsOrderBy, applicationsOnSetPagination, applicationsOnChangeOrderBy}) => {

  if(!show || !vacancie)
    return null

  return(
    <section>
      <ApplicationsList
        show={component === 'applications'}
        onClickEdit={onClickSeeker}
        filter={{jobId: vacancie.jobId}}
        applications={applications}
        applicationsCount={applicationsCount}
        onChangeOrderBy={applicationsOnChangeOrderBy}
        onSetPagination={applicationsOnSetPagination}
        applicationsOrderBy={applicationsOrderBy}
        pagination={applicationsPagination}
      />
      <VacanciesItem
        show={component === 'edit'}
        data={vacancie}
      />
      <VacanciesStatistics
        show={component === 'statistics'}
        apply={impressions}
        statistics={statistics}
      />
      <VacancieQuestions
        show={component === 'question'}
        data={vacancie}
      />
      <Preview
        show={component === 'preview'}
        data={vacancie}
      />
    </section>
  )
}

export default Vacancie