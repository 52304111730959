import React from 'react';
import { Segment, Header } from 'semantic-ui-react';
import MacBook from '../macbook';
import { IPhone } from '../';

const Preview = (props) => {

  if(!props.show || !props.data)
    return null

  return(
    <div>
      <Header as='h2' attached='top' className={'background-blue'}>
        {props.data.name}
      </Header>
      <Segment attached >
        <MacBook
          url={`https://bhmm.de/jobs/${props.data.jobId}?utm_source=preview`}
        />
      </Segment>
    </div>
  )
}

export default Preview