import axios from 'axios';
import { Buffer } from 'buffer';
import { getAccessToken, getRefreshToken, setAccessToken, logout } from './common';

axios.interceptors.request.use(
  (config) => {
    const accessToken = getAccessToken()
    if (accessToken) {
      config.headers["x-auth-token"] = 'Bearer ' + accessToken;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    let refreshToken = getRefreshToken();
    if (refreshToken && error.response.status === 401 && originalRequest._retry !== true) {
      originalRequest._retry = true;
      var auth = new Buffer.from(window._env_.REACT_APP_API_USER + ":" + window._env_.REACT_APP_API_PASSWORD).toString("base64")
      var config = {
        method: 'post',
        url: `${window._env_.REACT_APP_API_URL}/auth/refreshtoken`,
        headers: { 
          Authorization: 'Basic ' + auth
        },
        data : { refreshToken: refreshToken }
      };
      return axios(config)
        .then((res) => {
          if (res.status === 200) {
            setAccessToken(res.data.accessToken)
            console.log("Access token refreshed!");
            return axios(originalRequest);
          }
          if (res.status === 401) {
            logout()
          }
        }).catch(err => {
          logout()
        });
    }
    return Promise.reject(error);
  }
);

export const verify_token = async (token) => {
  if(token){
    try{
      var auth = new Buffer.from(window._env_.REACT_APP_API_USER + ":" + window._env_.REACT_APP_API_PASSWORD).toString("base64")
      var config = {
        method: 'get',
        url: `${window._env_.REACT_APP_API_URL}/auth/authorize/${token}`,
        headers: { 
          Authorization: 'Basic ' + auth
        }
      };
      var result = await axios(config).catch(err => {
        logout()
        throw new Error(err.response.statusText);
      });
      if(result.status === 200){
        return ({success: true, data: result.data});
      }
      else
        return ({success: false, data: result.data});
    }
    catch (err) {
      logout()
      return null;
    }
  }
  else{
    return null
  }
}

export const check_authentication = async (token) => {
  try{
    var auth = new Buffer.from(window._env_.REACT_APP_API_USER + ":" + window._env_.REACT_APP_API_PASSWORD).toString("base64")
    var config = {
      method: 'get',
      url: `${window._env_.REACT_APP_API_URL}/auth/authorize/${token}`,
      headers: { 
        Authorization: 'Basic ' + auth
      }
    };
    var result = await axios(config).catch(err => {
      throw new Error(err.response.statusText);
    });
    if(result.status === 200){
      return true;
    }
    else
      return false
  }
  catch (err) {
    return false;
  }
}

export const fetch_job = async (jobId) => {
  var config = {
    method: 'get',
    url: `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/jobs/${jobId}`,
    headers: { 
      'Content-Type': 'application/json'
    }
  };
  try {
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
}

export const fetch_job_applications_count = async (jobId) => {
  var config = {
    method: 'get',
    url: `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/jobs/${jobId}/applicationsCount`,
    headers: { 
      'Content-Type': 'application/json'
    }
  };
  try {
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
}

export const fetch_job_statistics = async (jobId) => {
  var config = {
    method: 'get',
    url: `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/jobs/${jobId}/statistics`,
    headers: { 
      'Content-Type': 'application/json'
    }
  };
  try {
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
}

export const fetch_job_application_statistics = async (jobId) => {
  var config = {
    method: 'get',
    url: `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/jobs/${jobId}/applications/statistics`,
    headers: { 
      'Content-Type': 'application/json'
    }
  };
  try {
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
}

export const fetch_jobs = async (filter, orderBy, limit, skip) => {
  var url = `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/jobs?`
  if(filter){
    for (const key in filter) {
      if(key === 'visibility' || key === 'organizerId')
        url += `&${key}=${filter[key]}`
      else
        url += `&filter=AND(${key}:${filter[key]})`
    }
  }

  if(skip)
    url += `&skip=${skip}`
  if(limit)
    url += `&limit=${limit}`
  if(orderBy)
    url += `&orderBy=${orderBy}`
    
  var config = {
    method: 'get',
    url: url,
    headers: { 
      'Content-Type': 'application/json'
    }
  };
  try {
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
}

export const fetch_config_applications_state = async () => {
  var url = `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/workplace/applications/status`
    
  var config = {
    method: 'get',
    url: url,
    headers: { 
      'Content-Type': 'application/json'
    }
  };
  try {
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
}

export const fetch_applications = async (filter, orderBy, limit, skip) => {
  var url = `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/workplace/applications?`

  if(filter){
    for (const key in filter) {
      url += `&${key}=${filter[key]}`
    }
  }

  if(skip)
    url += `&skip=${skip}`
  if(limit)
    url += `&limit=${limit}`
  if(orderBy)
    url += `&orderBy=${orderBy}`
    
  var config = {
    method: 'get',
    url: url,
    headers: { 
      'Content-Type': 'application/json'
    }
  };
  try {
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
}

export const fetch_application = async (applicationId) => {
  var url = `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/workplace/applications/${applicationId}`
    
  var config = {
    method: 'get',
    url: url,
    headers: { 
      'Content-Type': 'application/json'
    }
  };
  try {
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
}

export const fetch_locations = async () => {
  var url = `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations?tiny=${true}`

  var config = {
    method: 'get',
    url: url,
    headers: { 
      'Content-Type': 'application/json'
    }
  };
  try {
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
}

export const fetch_seeker = async (seekerId) => {
  try{
    var url = `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/workplace/seekers/${seekerId}`
  
    var config = {
      method: 'get',
      url: url,
      headers: { 
        'Content-Type': 'application/json'
      }
    };
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
}

export const update_seeker_rating = async (seekerId, rating) => {
  try{
    var data = JSON.stringify({
      "rating": rating
    });

    var config = {
      method: 'post',
      url: `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/workplace/seekers/${seekerId}/rating`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
}

export const upload_file = async (applicationId, file) => {
  try{
    let formData = new FormData();
    formData.append("file", file);

    var config = {
      method: 'post',
      url: `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/workplace/applications/${applicationId}/upload`,
      headers: { 
        "Content-Type": "multipart/form-data"
      },
      data : formData
    };
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
}

export const download_file = async (applicationId, filename) => {
  try{
    var token = getAccessToken()
    if(token) {
      var url = `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/workplace/applications/${applicationId}/files/${filename}`
      var headers = new Headers()
      headers.append("Authorization", `Bearer ${token}`)

      var requestOptions = {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
      };

      fetch(url, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `sample.${filename}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch(error => console.log('error', error));
    }
  }
  catch(error) {
    return {success: false, message: error}
  }
}

export const fetch_appointments = async (applicationId, limit, skip) => {
  var url = `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/workplace/applications/${applicationId}/appointments?`

  if(skip)
    url += `&skip=${skip}`
  if(limit)
    url += `&limit=${limit}`
    
  var config = {
    method: 'get',
    url: url,
    headers: { 
      'Content-Type': 'application/json'
    }
  };
  try {
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
}

export const insert_appointments = async (applicationId, object) => {
  try{
    var data = JSON.stringify(object);

    var config = {
      method: 'post',
      url: `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/workplace/applications/${applicationId}/appointments`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
}

export const delete_appointments = async (applicationId, appointmentId) => {
  try{
    var config = {
      method: 'delete',
      url: `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/workplace/applications/${applicationId}/appointments/${appointmentId}`,
      headers: { 
        'Content-Type': 'application/json'
      }
    };
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
}

export const fetch_comments = async (seekerId, limit, skip) => {
  var url = `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/workplace/seekers/${seekerId}/comments?`

  if(skip)
    url += `&skip=${skip}`
  if(limit)
    url += `&limit=${limit}`
    
  var config = {
    method: 'get',
    url: url,
    headers: { 
      'Content-Type': 'application/json'
    }
  };
  try {
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
}

export const insert_comment = async (seekerId, object) => {
  try{
    var data = JSON.stringify(object);

    var config = {
      method: 'post',
      url: `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/workplace/seekers/${seekerId}/comments`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
}

export const insert_jobSkill = async (jobId, name) => {
  try{
    var data = JSON.stringify({name:name});

    var config = {
      method: 'post',
      url: `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/workplace/jobs/${jobId}/skills`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
}

export const delete_jobSkill = async (jobId, id) => {
  try{
    var config = {
      method: 'delete',
      url: `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/workplace/jobs/${jobId}/skills/${id}`,
      headers: { 
        'Content-Type': 'application/json'
      }
    };
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
}

export const insert_jobAktivitie = async (jobId, name) => {
  try{
    var data = JSON.stringify({name:name});

    var config = {
      method: 'post',
      url: `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/workplace/jobs/${jobId}/activities`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
}

export const delete_jobActivitie = async (jobId, id) => {
  try{
    var config = {
      method: 'delete',
      url: `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/workplace/jobs/${jobId}/activities/${id}`,
      headers: { 
        'Content-Type': 'application/json'
      }
    };
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
}

export const insert_jobInterview = async (jobId, interview) => {
  try{
    var data = JSON.stringify(interview);

    var config = {
      method: 'post',
      url: `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/workplace/jobs/${jobId}/interview`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
}

export const delete_jobInterview = async (jobId, id) => {
  try{
    var config = {
      method: 'delete',
      url: `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/workplace/jobs/${jobId}/interview/${id}`,
      headers: { 
        'Content-Type': 'application/json'
      }
    };
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
}

export const update_job = async (jobId, data) => {
  try{
    var data = JSON.stringify(data);

    var config = {
      method: 'patch',
      url: `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/workplace/jobs/${jobId}`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
}

export const insert_job = async (data) => {
  try{
    var data = JSON.stringify(data);

    var config = {
      method: 'post',
      url: `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/workplace/jobs`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
}

export const insert_user = async (data) => {
  try{
    var data = JSON.stringify(data);

    var config = {
      method: 'post',
      url: `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/workplace/users`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
}

export const delete_user = async (guid) => {
  try{
    var config = {
      method: 'delete',
      url: `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/workplace/users/${guid}`,
      headers: { 
        'Content-Type': 'application/json'
      }
    };
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
}

export const fetch_users = async (filter, limit, skip) => {
  var url = `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/workplace/users`
  if(filter || limit || skip)
    url += `?`
  if(filter){
    for (const key in filter) {
      url += `&filter=AND(${key}:${filter[key]})`
    }
  }

  if(skip)
    url += `&skip=${skip}`
  if(limit)
    url += `&limit=${limit}`
  var config = {
    method: 'get',
    url: url,
    headers: { 
      'Content-Type': 'application/json'
    }
  };
  try {
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
}

export const fetch_me = async () => {
  var url = `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/workplace/users/me`
    
  var config = {
    method: 'get',
    url: url,
    headers: { 
      'Content-Type': 'application/json'
    }
  };
  try {
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
}

export const fetch_user = async (guid) => {
  var url = `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/workplace/users/${guid}`
    
  var config = {
    method: 'get',
    url: url,
    headers: { 
      'Content-Type': 'application/json'
    }
  };
  try {
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
}

export const update_me = async (data) => {
  var url = `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/workplace/users/me`
  var data = JSON.stringify(data);
    
  var config = {
    method: 'post',
    url: url,
    headers: { 
      'Content-Type': 'application/json'
    },
    data: data
  };
  try {
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
}

export const update_application = async (applicationId, data) => {
  try{
    var data = JSON.stringify(data);

    var config = {
      method: 'patch',
      url: `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/workplace/applications/${applicationId}`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
}

export const fetch_notifications = async () => {
  var url = `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/workplace/notifications/me`
    
  var config = {
    method: 'get',
    url: url,
    headers: { 
      'Content-Type': 'application/json'
    }
  };
  try {
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
}

export const delete_notification = async (notificationId) => {
  try{
    var config = {
      method: 'delete',
      url: `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/workplace/notifications/${notificationId}`,
      headers: { 
        'Content-Type': 'application/json'
      }
    };
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
}

export const fetch_active_jobs = async () => {
  var url = `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/workplace/jobs/active`

  var config = {
    method: 'get',
    url: url,
    headers: { 
      'Content-Type': 'application/json'
    }
  };
  try {
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
}

export const insert_application = async (jobId, application) => {
  try{
    var data = JSON.stringify(application);
    var config = {
      method: 'post',
      url: `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/jobs/${jobId}/applications`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data: data
    };
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
}

export const delete_user_refreshToken = async () => {
  var refreshToken = getRefreshToken()
  if(refreshToken) {
    try{
      var data = JSON.stringify({
        refreshToken: refreshToken
      });
      var config = {
        method: 'post',
        url: `${window._env_.REACT_APP_API_URL}/auth/signout`,
        headers: { 
          'Content-Type': 'application/json'
        },
        data: data
      };
      var result = await axios(config)
      return result.data
    }
    catch(error) {
      return {success: false, message: error}
    }
  }
}