import React from 'react';
import { Container, Segment, Header, Icon, Button, Menu  } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const Smart = () => {
  const { t } = useTranslation();
  const smartMenu = useSelector((state) => state.smartMenu)

  if(!smartMenu)
    return null
    
  return(
    <Container fluid>
      <Segment placeholder padded='very' textAlign='center' >
        <Header as='h1'>
          {smartMenu.headline}
          <Header.Subheader>
            {smartMenu.subHeadline}
          </Header.Subheader>
        </Header>
        <Segment.Inline>
          {smartMenu.buttons && smartMenu.buttons.map((element, index) =>(
            <Button key={`button-smart_menu-#${index}`} primary size='medium' onClick={element.onButtonClick} ><Icon name={element.icon} />{t(element.name)}</Button>
          ))}
        </Segment.Inline>
          {smartMenu.navigation && 
            <Menu text className={'inline'} style={{ position: 'absolute', bottom: 0, margin: 0}} >
              {smartMenu.navigation.map((element, index)=> (
                <Menu.Item
                  key={`navigation-smart_menu-#${index}`}
                  name={t(element.name)}
                  onClick={element.onMenuClick}
                />
              ))}
            </Menu>
          }
      </Segment>
    </Container>
  )
}

export default Smart