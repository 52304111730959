import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setSmartMenu, fetchUser } from '../redux/actions';
import { User } from '../components';
import { delete_user } from '../utils/requestHelper';
import { useNavigate } from 'react-router-dom';

const Member = (props) => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state)=>state.user);

  useEffect(()=>{
    const { guid } = params
    dispatch(fetchUser(guid))
  },[props])

  useEffect(()=>{
    if(user)
      dispatch(setSmartMenu({
        headline: `${user.givenname}, ${user.surname}`,
        buttons: [
          { key: 'mail', name: 'button-mail', icon: 'mail', onButtonClick: ()=>{window.location.href=`mailto:${user.mail}`}},
          { key: 'call', name: 'button-call', icon: 'call', onButtonClick: ()=>{window.location.href=`tel:${user.phone}`}}
        ],
        navigation: null
      }))
  },[user])

  const handleOnDeleteUser = async (target) => {
    var result = await delete_user(target.guid)
    if(result.success === true) {
      navigate(`/members`)
    }
  }

  return (
    <article>
      <User
        show={true}
        user={user}
        onClickDelete={handleOnDeleteUser}
      />
    </article>
  );
}

export default Member;