import { JOBS_FETCH, JOBS_ORDERBY, JOBS_FILTER, JOBS_PAGINATION, JOB_FETCH, NETWORK_START, NETWORK_ERROR, NETWORK_STOP } from "../constants/action-types";
import { fetch_jobs, fetch_job, update_job } from "../../utils/requestHelper";

export function setJobsOrderBy(value){
  return (dispatch) => {
    dispatch({type: JOBS_ORDERBY, value: value})
  }
}

export function setJobsFilter(value){
  return (dispatch) => {
    dispatch({ type: JOBS_FILTER, value: value })
  }
}

export function setJobsPagination(value){
  return (dispatch) => {
    dispatch({ type: JOBS_PAGINATION, value: value })
  }
}

export function fetchJobs(filter, orderBy, limit, skip){
  return async (dispatch) => {
    try {
      dispatch({ type: NETWORK_START });
      const result = await fetch_jobs(filter, orderBy, limit, skip)
      if(result.success === true){
        dispatch({ type: JOBS_FETCH, result: result.data, count: result.count })
      }
      dispatch({ type: NETWORK_STOP });
    } catch (error) {
      dispatch({ type: NETWORK_STOP });
      dispatch({ type: NETWORK_ERROR, error });
    }
  };
}

export function fetchJob(jobId){
  return async (dispatch) => {
    try {
      dispatch({ type: NETWORK_START });
      const result = await fetch_job(jobId)
      if(result.success === true){
        dispatch({ type: JOB_FETCH, result: result.data })
      }
      dispatch({ type: NETWORK_STOP });
    } catch (error) {
      dispatch({ type: NETWORK_STOP });
      dispatch({ type: NETWORK_ERROR, error });
    }
  };
}

export function updateJob(jobId, data){
  return async (dispatch) => {
    try {
      dispatch({ type: NETWORK_START });
      const result = await update_job(jobId, data)
      if(result.success === true){
        dispatch({ type: JOB_FETCH, result: result.data })
      }
      dispatch({ type: NETWORK_STOP });
    } catch (error) {
      dispatch({ type: NETWORK_STOP });
      dispatch({ type: NETWORK_ERROR, error });
    }
  };
}