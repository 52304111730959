import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Form, Button, Icon, Header } from 'semantic-ui-react';
import { insert_user } from '../../utils/requestHelper';

function removeEmptyStrings(obj){
  let newObj = {};
  Object.keys(obj).forEach((prop) => {
    if (obj[prop] !== null && obj[prop] !== '') { newObj[prop] = obj[prop] }
    if (obj[prop] === 0) { newObj[prop] = obj[prop] }
  })
  return newObj;
};

const NewMember = ({hide}) => {
  const {t} = useTranslation();
  const [ permissions, setPermissions ] = useState(["member", "admin"])
  const [ data, setData ] = useState()
  const [ errors, setErrors ] = useState({})

  const permissionOptions = permissions ? permissions.map((lo, index) => ({
    key: `permission-key-${index}`, id: 'permission', value: lo, text: `${lo}`
  })) : null

  const handleOnChange = (e) => {
    const { id, value } = e.target
    setData(prevData => ({
        ...prevData,
        [id]: value
    }))
  }

  const handleOnSelectChange = (e, { value, id }) => {
    setData(prevData => ({
      ...prevData,
      [id]: value
    }))
  }

  const handleOnSubmit = async () => {
    if(data) {
      let obj = removeEmptyStrings(data)
      let newErrors = {}
      let error = false
      if(!obj.hasOwnProperty('givenname')) {
        newErrors.givennameError = t('input-error-givenname')
        error = true
      }
      if(!obj.hasOwnProperty('surname')) {
        newErrors.surnameError = t('input-error-surname')
        error = true
      }
      if(!obj.hasOwnProperty('mail')) {
        newErrors.mailError = t('input-error-mail')
        error = true
      }
      if(!obj.hasOwnProperty('permission')) {
        newErrors.mailError = t('input-error-permission')
        error = true
      }
    
      setErrors({
        ...newErrors
      })
      if(error === false){
        var result = await insert_user(obj)
        if(result.success === true)
          hide()
      }
    }
  }

  return(
    <>
      <Modal.Header>{t(`headline-new_member`)}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form>
            <Header size='small'>{t('label-profile')}</Header>
            <Form.Group widths='equal'>
              <Form.Input id='givenname' error={errors.hasOwnProperty('givennameError') && { content: errors.givennameError }} onChange={handleOnChange} required label={t(`input-label-givenname`)} placeholder={t(`input-placeholder-givenname`)} />
              <Form.Input id='surname' error={errors.hasOwnProperty('surnameError') && { content: errors.surnameError }} onChange={handleOnChange} required label={t(`input-label-surname`)} placeholder={t(`input-placeholder-surname`)} />
            </Form.Group>
            <Form.Input id='mail' error={errors.hasOwnProperty('mailError') && { content: errors.mailError }} onChange={handleOnChange} required label={t(`input-label-mail`)} placeholder={t(`input-placeholder-mail`)} />
            <Form.Input id='phone' onChange={handleOnChange} label={t(`input-label-phone`)} placeholder={t(`input-placeholder-phone`)} />
            <Header size='small'>{t('label-settings')}</Header>
            <Form.Select
              fluid
              required
              id='permission'
              label={t(`input-label-permission`)}
              options={permissionOptions && permissionOptions}
              placeholder={t(`input-placeholder-permission`)}
              onChange={handleOnSelectChange}
              error={errors.hasOwnProperty('permissionError') && { content: errors.permissionError }}
            />
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={hide}>
          <Icon name='times' />
          {t(`button-cancel`)}
        </Button>
        <Button onClick={handleOnSubmit} primary>
          <Icon name='check' />
          {t(`button-create`)}
        </Button>
      </Modal.Actions>
    </>
  )
}

export default NewMember