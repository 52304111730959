import React from 'react';
import { Grid, Header, Icon, Button, Label, Image  } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import user_image from './images/defaultUser.jpg'

const UsersListItem = (props) => {
  const { t } = useTranslation();

  return(
    <Grid columns='equal' stackable>
      <Grid.Column textAlign='left'>
        <Image src={user_image} size='tiny' circular alt='bhmm-member'/>
      </Grid.Column>
      <Grid.Column textAlign='left' verticalAlign='middle'>
        <Header as='h2'>{`${props.givenname}, ${props.surname}`}</Header>
      </Grid.Column>
      <Grid.Column>
        <Header style={{fontSize: '1.4rem'}}>{t('label-contact')}</Header>
        <p>
          {props.mail}<br/>
          {props.phone}<br/>
        </p>
      </Grid.Column>
      <Grid.Column>
        <Header style={{fontSize: '1.4rem'}}>{t('label-permission')}</Header>
        <p>
          {props.permission}
        </p>
      </Grid.Column>
      <Grid.Column>
        <Button.Group floated='right'>
          <Button primary size='medium' onClick={()=>props.onClickEdit({guid: props.guid})}><Icon name='edit' />{t('button-detail')}</Button>
        </Button.Group>
      </Grid.Column>
    </Grid>
  )
}

export default UsersListItem