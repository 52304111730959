import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux'
import { Grid, Segment, Image, Header, Icon, Button } from 'semantic-ui-react';
import { download_file, upload_file } from '../../utils/requestHelper';
import { useTranslation } from 'react-i18next';

import txt from '../../assets/icon_txt.svg'
import jpg from '../../assets/icon_jpg.svg'
import doc from '../../assets/icon_doc.svg'
import pdf from '../../assets/icon_pdf.svg'
import png from '../../assets/icon_png.svg'
import ppt from '../../assets/icon_ppt.svg'
import xls from '../../assets/icon_xls.svg'
import zip from '../../assets/icon_zip.svg'
import { fetchApplication } from '../../redux/actions';

const Documents = ({show, documents, applicationId}) => {
  const { t } = useTranslation();
  const inputFile = useRef(null);
  const [ data, setData ] = useState();
  const dispatch = useDispatch();

  const handleOnAddFile = () => {
    inputFile.current.click();
   };
 
  const handleOnFileChange = async (e) => {
    if(!e.target.files[0])
      return
    if(e.target.files[0].size > 5300000){
      alert("File is too big!");
      return
    };
    let response = await upload_file(applicationId,e.target.files[0])
    dispatch(fetchApplication(applicationId))
  };

  if(!show)
    return null

  return(
    <Segment basic color='blue' padded='very' textAlign='left'>
      <Grid columns={2}>
        <Grid.Column>
          <Header as='h3'>
            {t(`headline-attachments`)}
          </Header>
        </Grid.Column>
        <Grid.Column>
          <input type='file' id='file' ref={inputFile} style={{display: 'none'}} onChange={handleOnFileChange} accept=".pdf,.docx,.doc"/>
          <Button primary animated='vertical' onClick={handleOnAddFile} floated='right'>
            <Button.Content hidden>{t(`button-upload`)}</Button.Content>
            <Button.Content visible>
              <Icon name='upload' />
            </Button.Content>
          </Button>
        </Grid.Column>
      </Grid>
      <Grid textAlign='left' columns={'equal'} >
        {documents.length > 0 ? documents.map((element,index)=>(
          renderDocument(index, element)
        )) :
        <Grid.Column textAlign='center' key={`attachment-#-1`}>
          <Header as='h2' icon>
            <Icon name='file alternate outline' circular inverted color='blue' />
              {t('headline-no_documents')}
            <Header.Subheader>
              {t('content-no_documents')}
            </Header.Subheader>
          </Header>
        </Grid.Column>
        }
      </Grid>
    </Segment>
  )
}

export default Documents

function renderDocument(index, element) {

  const status = {
    '.txt': txt,
    '.png': png,
    '.jpg': jpg,
    '.jpeg': jpg,
    '.doc': doc,
    '.docx': doc,
    '.pdf': pdf,
    '.ppt': ppt,
    '.pptx': ppt,
    '.xls': xls,
    '.xlsx': xls,
    '.csv': xls,
    '.zip': zip,
  }

  const getExtension = (name) => {
    let index = name.lastIndexOf('.');
    return status[name.substring(index)]
  }

  const handleDownload = async () => {
    download_file(element.applicationId, element.name)
  }
  
  return <Grid.Column key={`application-attachments-#${index}`} width={8} >
    <Segment raised onClick={handleDownload} style={{background: '#f9fafb', cursor: 'pointer'}}>
      <Image src={getExtension(element.name)} size='mini' verticalAlign='middle' />{' '}
      <span>{element.name.length > 40 ? `${element.name.substring(0,40)}...` : element.name}</span>
    </Segment>
  </Grid.Column>;
}
