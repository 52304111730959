import { Route, Routes } from 'react-router-dom';
import Layout from './layout';
import { Application, Candidate, Jobs, Job, Members, Member } from './pages';
import Authentication from './components/authentication';
import PrivateRoute from './utils/privateRoute';

const Router = () => {

  return (
    <Routes>
      <Route path="/authentication" element={<Authentication />} />
      <Route path="/applications/:applicationId/candidates/:candidateId" element={<PrivateRoute><Layout><Candidate /></Layout></PrivateRoute>} />
      <Route path="/applications" element={<PrivateRoute><Layout><Application /></Layout></PrivateRoute>} />
      <Route path="/jobs/:jobId" element={<PrivateRoute><Layout><Job /></Layout></PrivateRoute>} />
      <Route path="/jobs" element={<PrivateRoute><Layout><Jobs /></Layout></PrivateRoute>} />
      <Route path="/members" element={<PrivateRoute permissions={["admin"]}><Layout><Members /></Layout></PrivateRoute>} />
      <Route path="/members/:guid" element={<PrivateRoute permissions={["admin"]}><Layout><Member /></Layout></PrivateRoute>} />
      <Route path="/" element={<RedirectExternalLogin />} />
      {/* Not Found */}
      <Route
        path="*"
        element={<RedirectExternalLogin />}
      />
    </Routes>
  );
};

function RedirectExternalLogin() {
  window.location = `${window._env_.REACT_APP_AUTH_URL}?redirectURL=${window._env_.REACT_APP_HR_URL}/authentication`
  return null
}

export default Router;