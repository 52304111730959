import React from 'react';
import { Grid, Segment, Image, Header, Label, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import Comments from './comments';
import Basic from './basic';
import Applications from './applications';
import user_image from './images/defaultUser.jpg';
import Questions from './questions';
import Documents from './documents';
import Application from './application';
import moment from 'moment';
import Appointments from './appointments';
import ButtonsSegment from './buttons';

const Seeker = ({show, seeker, application, applications, applicationsCount, appointments, appointmentsCount, comments, commentsCount, component}) => {
  const { t } = useTranslation();

  if(!show || !seeker || !application )
    return null

  return(
    <section>
      <div>
        <Grid columns='equal' stackable >
          <Grid.Column textAlign='center'>
            <Segment basic padded='very'>
              <Label color='red'  attached='top left' >
                <Icon name='check' /> Talent Pool
              </Label>
              <div>
                <Image centered src={user_image} size='small' circular alt='application_seeker'/>
              </div>
              <Header as='h2'>
                {`${seeker.givenname} ${seeker.surname}`}
                <Header.Subheader>
                  {seeker.streetAddress}
                </Header.Subheader>
                <Header.Subheader>
                  {`${seeker.postalCode} ${seeker.location}`}
                </Header.Subheader>
              </Header>
            </Segment>
            <Comments
              show={true}
              seekerId={seeker.seekerId}
              rating={seeker.rating}
              ratingCount={seeker.ratingCount}
              comments={comments}
              commentsCount={commentsCount}
            />
          </Grid.Column>
          <Grid.Column  width={10}>
            <Basic
              show={component === 'seeker'}
              givenname={seeker.givenname}
              surname={seeker.surname}
              mail={seeker.mail}
              phone={seeker.phone}
              streetAddress={seeker.streetAddress}
              postalCode={seeker.postalCode}
              location={seeker.location}
            />
            <Appointments
              show={component === 'seeker'}
              seekerMail={seeker.mail}
              appointments={appointments}
              appointmentsCount={appointmentsCount}
              applicationId={application.applicationId}
            />
            <Applications
              show={component === 'seeker'}
              seekerId={seeker.seekerId}
              applications={applications}
              applicationsCount={applicationsCount}
            />
            <Application 
              show={component === 'application'}
              applicationId={application.applicationId}
              status={application.status}
              createdAt={moment(application.createdAt).format('DD.MM.YYYY')}
              modifiedAt={moment(application.modifiedAt).format('DD.MM.YYYY')}
              job={application.job}
              organizer={application.organizer}
            />
            <Questions
              show={component === 'application'}
              questions={application.attributes}
            />
            <Documents
              show={component === 'application'}
              documents={application.attachments}
              applicationId={application.applicationId}
            />
            <ButtonsSegment
              show={component === 'application'}
              applicationId={application.applicationId}
            />
          </Grid.Column>
        </Grid>
      </div>
    </section>
  )
}

export default Seeker