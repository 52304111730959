import React from 'react';
import './macbook.css'

const MacBook = ({url}) => {

  return(
    <div class="css-mb">
      <div class="mb-display-position">
        <div class="mb-display">
          <div class="mb-screen-position">
            <div class="mb-screen">
              <iframe src={url} frameborder="0"></iframe>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-body"></div>
      <div class="mb-bottom-cover"></div>
    </div>
  )
}

export default MacBook