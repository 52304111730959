import { delete_user_refreshToken } from './requestHelper'

export const getAccessToken = () => {
  return localStorage.getItem('bhmm.accessToken') || null
}

export const setAccessToken = (token) => {
  if(token) {
    localStorage.setItem('bhmm.accessToken', token)
  }
}

export const getRefreshToken = () => {
  return localStorage.getItem('bhmm.refreshToken') || null
}

export const setRefreshToken = (token) => {
  if(token) {
    localStorage.setItem('bhmm.refreshToken', token)
  }
}
 
const removeSession = async () => {
  localStorage.removeItem('bhmm.accessToken')
  localStorage.removeItem('bhmm.refreshToken')
  localStorage.clear()
}

export const logout = async () => {
  await delete_user_refreshToken();
  removeSession();
  window.location.reload();
}