import React from 'react';
import { Segment, Header, Icon, Pagination } from 'semantic-ui-react';
import UsersListItem from './usersListItem';
import { useTranslation } from 'react-i18next';

const UsersList = ({show, users, pagination, onClickEdit, onSetPagination}) => {
  const {t} = useTranslation()

  if(!show)
    return null

  return(
    <section>
      <Header as='h2' attached='top' className={'background-blue'} style={{display: 'flex'}}>
        <Pagination
          activePage={pagination.activePage}
          onPageChange={onSetPagination}
          firstItem={null}
          lastItem={null}
          pointing
          secondary
          inverted
          style={{borderColor: 'transparent'}}
          totalPages={pagination.totalPages}
        />
      </Header>
      <Segment attached >
        {users.length > 0 ? users.map((element, index) => (
          <UsersListItem
            key={`userListItem-#${element.guid}`}
            givenname={element.givenname}
            surname={element.surname}
            mail={element.mail}
            phone={element.phone}
            permission={element.permission}
            guid={element.guid}
            onClickEdit={onClickEdit}
          />
        )) : 
        <Header as='h2' icon textAlign='center'>
          <Icon name='sticky note outline' color='blue' circular inverted />
            {t('headline-no_users')}
          <Header.Subheader>
            {t('content-no_users')}
          </Header.Subheader>
        </Header>
        }
      </Segment>
    </section>
  )
}

export default UsersList