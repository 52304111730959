import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Grid, Segment, Icon, Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { updateApplication } from '../../redux/actions';

const ButtonsSegment = ({show, applicationId}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate()  

  if(!show)
    return null

  const handleOnChangeStateToDeleted = async () => {
    dispatch(updateApplication(applicationId, {status: 0}))
    navigate('/applications')
  }

  return(
    <Segment basic color='blue' padded='very' textAlign='left'>
      <Grid textAlign='left' columns={1}>
        <Grid.Row key={`application-buttonslist`}>
          <Grid.Column floated='right'>
            <Button floated='right' key={`button-application-delete`} onClick={handleOnChangeStateToDeleted} size='medium' ><Icon name={'trash alternate'} />{t('button-delete')}</Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )
}

export default ButtonsSegment