import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationsList, SearchBar } from '../components';
import { useTranslation } from 'react-i18next';
import { fetchLocations, setSmartMenu, updateSmartMenu, fetchMe, fetchApplications, setApplicationsOrderBy, setApplicationsFilter, setApplicationsPagination } from '../redux/actions';
import { useNavigate } from 'react-router-dom';
import { useModal } from '../hooks/useModal';
import NewVacancie from '../components/modal/newVacancie';
import NewApplication from '../components/modal/newApplication';

const Application = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const me = useSelector((state)=>state.me);
  const applications = useSelector((state)=>state.applications);
  const applicationsCount = useSelector((state)=>state.applicationsCount);
  const applicationsOrderBy = useSelector((state)=>state.applicationsOrderBy);
  const filter = useSelector((state)=>state.applicationsFilter);
  const pagination = useSelector((state)=>state.applicationsPagination)
  const itemsPerPage = 15

  const {show : showA, hide: hideA, RenderModal: RenderModalA} = useModal();
  const {show : showB, hide: hideB, RenderModal: RenderModalB} = useModal();

  useEffect(()=>{
    handleOnSetPagination(null, {activePage: pagination.activePage || 0})
    dispatch(fetchLocations())
    dispatch(fetchMe())
    dispatch(setSmartMenu({
      buttons: [
        { key: 'add_candidate', name: 'button-add_candidate', icon: 'add', onButtonClick: ()=>showB(true)},
        { key: 'add_job', name: 'button-add_job', icon: 'add', onButtonClick: ()=>showA(true)}
      ],
      navigation: null
    }))
  },[props])

  useEffect(()=>{
    if(me)
      dispatch(updateSmartMenu({
        headline: t('headline-welcome', {givenname: me.givenname})
      }))
  },[me])

  useEffect(()=>{
    dispatch(setApplicationsPagination({
      ...pagination,
      totalPages: Math.ceil(applicationsCount / itemsPerPage)
    }))
  },[applicationsCount])

  useEffect(()=>{
    let multi = itemsPerPage * (pagination.activePage -1)
    if(pagination.activePage === 1)
      multi = 0
    dispatch(fetchApplications(filter, applicationsOrderBy, itemsPerPage, multi ))
  },[applicationsOrderBy])

  const handleOnClickSeekerDetails = async (target) => {
    navigate(`/applications/${target.applicationId}/candidates/${target.seekerId}`)
  }

  const handleOnFilterApplications = async(values) => {
    dispatch(setApplicationsFilter(values))
    dispatch(fetchApplications(values, applicationsOrderBy, itemsPerPage, 0))
  }

  const handleOnChangeOrderBy = (e, { value }) => {
    dispatch(setApplicationsOrderBy(value))
  }

  const handleOnSetPagination = (e, { activePage }) => {
    let multi = itemsPerPage * (activePage -1)
    if(activePage === 1)
      multi = 0
    dispatch(fetchApplications(filter, applicationsOrderBy, itemsPerPage, multi ))
    dispatch(setApplicationsPagination({
      ...pagination,
      activePage: activePage
    }))
  }

  return (
    <article>
      <SearchBar
        show={true}
        currentFilter={filter}
        onSearch={handleOnFilterApplications}
        user={me}
      />
      <ApplicationsList
        show={true}
        onClickEdit={handleOnClickSeekerDetails}
        onChangeOrderBy={handleOnChangeOrderBy}
        onSetPagination={handleOnSetPagination}
        applicationsOrderBy={applicationsOrderBy}
        pagination={pagination}
        applications={applications}
        applicationsCount={applicationsCount}
      />
      <RenderModalA>
        <NewVacancie
          hide={hideA}
        />
      </RenderModalA>
      <RenderModalB>
        <NewApplication
          hide={hideB}
        />
      </RenderModalB>
    </article>
  );
}

export default Application;