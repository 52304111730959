import React, { useState } from 'react';
import { Comment, Segment, Form, Header, Button, Rating } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import user_image from './images/defaultUser.jpg';
import { insert_comment, update_seeker_rating } from '../../utils/requestHelper';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { fetchComments } from '../../redux/actions';

const Comments = ({show, seekerId, rating, ratingCount, comments, commentsCount}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [ rate, setRate ] = useState();
  const [ comment, setComment ] = useState();

  if(!show || !seekerId)
    return null

  const handleOnRate = async (e, { rating }) => {
    var result = await update_seeker_rating(seekerId, rating)
    if(result){
      setRate(true)
    }
  }

  const handleOnComment = async () => {
    if(!comment)
      return
    var result = await insert_comment(seekerId, {comment:comment})
    if(result.success === true){
      setComment('')
      dispatch(fetchComments(seekerId, 10, 0))
    }
  }

  const handleOnChange = (e) => {
    const { value } = e.target
    setComment(value)
  }

  return(
    <Segment basic color='blue' padded='very' textAlign='left'>
      <Header as='h3'>
        {t(`headline-comments`)}
      </Header>
      <Rating maxRating={5} defaultRating={rating / ratingCount} icon='star' disabled={rate} onRate={handleOnRate} />
      <Comment.Group>
        {comments && comments.slice(0).reverse().map((element, index)=>(
          renderComments(index, element)
        ))}
        <Form reply onSubmit={handleOnComment}>
          <Form.TextArea onChange={handleOnChange} value={comment}/>
          <Button content={t(`button-add_comment`)} labelPosition='left' icon='edit' primary />
        </Form>
      </Comment.Group>
    </Segment>
  )
}

export default Comments

function renderComments(index, element) {
  return <Comment key={`comment-#${index}`}>
    <Comment.Avatar as='a' src={user_image} />
    <Comment.Content>
      <Comment.Author>{`${element.user.givenname} ${element.user.surname}`}</Comment.Author>
      <Comment.Metadata>
        <div>{moment(element.createdAt).fromNow()}</div>
      </Comment.Metadata>
      <Comment.Text>{element.comment}</Comment.Text>
    </Comment.Content>
  </Comment>;
}
