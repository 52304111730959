import React from 'react';
import { Grid, Header, Icon, Button, Label, Image, Rating  } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import user_image from './images/defaultUser.jpg'

const ApplicationsListItem = (props) => {
  const { t } = useTranslation();
  const applicationStatus = useSelector((state)=>state.config_applications_state)

  return(
    <Grid columns='equal' stackable verticalAlign='middle'>
      <Grid.Column textAlign='left'>
        {props.status === 2 && <Label as='a' color='red' ribbon>
          <Icon name='info'/>
          {t('label-new')}
        </Label>}
        <Image src={user_image} size='tiny' circular alt='application_seeker'/>
      </Grid.Column>
      <Grid.Column textAlign='left'>
        <Header as='h2'>{`${props.givenname}, ${props.surname}`}</Header>
        <Label size='medium' color={'orange'}>
          {props.jobName}
        </Label> 
      </Grid.Column>
      <Grid.Column textAlign='center'>
        <Rating icon='star' defaultRating={props.rating / props.ratingCount} maxRating={5} disabled />
      </Grid.Column>
      <Grid.Column>
        <Icon color='blue' name='plus circle' />{props.createdAt}<br />
        <Icon color='blue' name='sync' />{props.modifiedAt}
      </Grid.Column>
      <Grid.Column textAlign='center'>
        <Label size='medium' color={applicationStatus.find(x => x.key === props.status).color}>
          {t(applicationStatus.find(x => x.key === props.status).label)}
        </Label>  
      </Grid.Column>
      <Grid.Column>
        <Button.Group floated='right'>
          <Button primary size='medium' onClick={()=>props.onClickEdit({applicationId: props.applicationId, seekerId: props.seekerId})}><Icon name='edit' />{t('button-detail')}</Button>
        </Button.Group>
      </Grid.Column>
    </Grid>
  )
}

export default ApplicationsListItem