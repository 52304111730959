import React from 'react';
import { Segment, Header, Icon, Pagination, Dropdown } from 'semantic-ui-react';
import ApplicationsListItem from './applicationsListItem';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { applicationOrderBy } from '../../config/config';

const ApplicationsList = ({show, applications, applicationsOrderBy, pagination, onClickEdit, onChangeOrderBy, onSetPagination}) => {
  const {t} = useTranslation()

  if(!show)
    return null

  return(
    <section>
      <Header as='h2' attached='top' className={'background-blue'} style={{display: 'flex'}}>
        <Pagination
          activePage={pagination.activePage}
          onPageChange={onSetPagination}
          firstItem={null}
          lastItem={null}
          pointing
          secondary
          inverted
          style={{borderColor: 'transparent'}}
          totalPages={pagination.totalPages}
        />
        <Dropdown 
          text={t('input-label-sortby', {sortby: t(`label-${applicationsOrderBy}`)})}
          style={{marginRight: 0, marginLeft: 'auto'}}
        >
          <Dropdown.Menu>
            {applicationOrderBy.map(element => (
              <Dropdown.Item key={element.key} value={element.value} text={t(element.text)} onClick={onChangeOrderBy}/>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Header>
      <Segment attached >
        {applications.length > 0 ? applications.map((element, index) => (
          renderApplicationList(index, element, onClickEdit)
        )) : 
        <Header as='h2' icon textAlign='center'>
          <Icon name='sticky note outline' color='blue' circular inverted />
            {t('headline-no_applications')}
          <Header.Subheader>
            {t('content-no_applications')}
          </Header.Subheader>
        </Header>
        }
      </Segment>
    </section>
  )
}

export default ApplicationsList

function renderApplicationList(index, element, onClickEdit) {
  return <ApplicationsListItem
    key={index}
    seekerId={element.seeker && element.seeker.seekerId}
    givenname={element.seeker && element.seeker.givenname}
    surname={element.seeker && element.seeker.surname}
    mail={element.seeker && element.seeker.mail}
    phone={element.seeker && element.seeker.phone}
    jobName={element.job && element.job.name}
    applicationId={element.applicationId}
    createdAt={moment(element.createdAt).format('DD.MM.YYYY')}
    modifiedAt={moment(element.modifiedAt).fromNow()}
    rating={element.rating}
    ratingCount={element.ratingCount}
    status={element.status}
    onClickEdit={onClickEdit} />;
}