import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, Segment, Checkbox, Grid, Button } from 'semantic-ui-react';

const SearchBar = ({show, onSearch, user, currentFilter}) => {
  const {t} = useTranslation()
  const [ filter, setFilter ] = useState()

  useEffect(()=>{
    setFilter(currentFilter)
  },[currentFilter])

  if(!show || !user)
    return null

  const handleOnChange = (e, data) => {
    const { id, value } = e.target
    if(value !== '')
      setFilter({
        ...filter,
        [id]: value
      })
    else {
      let newFilter = filter
      delete newFilter[id]
      setFilter(newFilter)
    }
  }

  const handleOnFilter = (e, data) => {
    const { id, checked, value } = data
    if(checked) {
      setFilter({
        ...filter,
        [id]: value
      })
      onSearch({
        ...filter,
        [id]: value
      })
    }
    else {
      let newFilter = filter
      delete newFilter[id]
      setFilter(newFilter)
      onSearch(newFilter)
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSearch(filter)
    }
  }

  return (
    <section>
      <Grid columns={1}>
        <Grid.Column verticalAlign='middle' textAlign='center' >
          <Segment basic>
            <Input id='name' onKeyDown={handleKeyDown} placeholder={t('input-placeholder-search')} onChange={handleOnChange} action style={{minWidth: '35vw'}}>
              <input defaultValue={currentFilter && currentFilter.name ? currentFilter.name : null}/>
              <Button icon='search' primary content={t('button-search')} onClick={()=>onSearch(filter)} />
            </Input>
          </Segment>
        </Grid.Column>
      </Grid>
      <Grid columns='equal'>
        <Grid.Column verticalAlign='middle' textAlign='right'>
          <Segment basic>
            <Checkbox id='visibility' defaultChecked={currentFilter && currentFilter.visibility} value={1} onChange={handleOnFilter} slider label={t('input-label-search_archive')} />
          </Segment>
        </Grid.Column>
        <Grid.Column verticalAlign='middle' textAlign='left'>
          <Segment basic>
            <Checkbox id='organizerId' defaultChecked={currentFilter && currentFilter.organizerId} value={user.guid} onChange={handleOnFilter} slider label={t('input-label-search_me')} />
          </Segment>
        </Grid.Column>
      </Grid>
    </section>
  )
}

export default SearchBar;