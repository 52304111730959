import React from "react";
import ReactDOM from 'react-dom'
import { Modal } from 'semantic-ui-react';


const CustomModal = ({ children, closeModal }) => {

  const domEl = document.getElementById('modal-root')
  if (!domEl) 
    return null
  
  return ReactDOM.createPortal(
    <Modal dimmer='inverted' open={true} onClose={closeModal}>
      {children}
    </Modal>,
    domEl
  )
}

export default CustomModal;