import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'
import { Modal, Form, Button, Icon, Header } from 'semantic-ui-react';
import { fetch_active_jobs, insert_application } from '../../utils/requestHelper';

function removeEmptyStrings(obj){
  let newObj = {};
  Object.keys(obj).forEach((prop) => {
    if (obj[prop] !== null && obj[prop] !== '') { newObj[prop] = obj[prop] }
    if (obj[prop] === 0) { newObj[prop] = obj[prop] }
  })
  return newObj;
};

const NewApplication = ({hide}) => {
  const {t} = useTranslation();
  const navigate = useNavigate()
  const [ activeJobs, setActiveJobs ] = useState()
  const [ data, setData ] = useState()
  const [ errors, setErrors ] = useState({})

  useEffect(()=>{
    const fetchData = async () => {
      let data = await fetch_active_jobs()
      if(data.success == true)
        setActiveJobs(data.data)
    }
    fetchData()
  },[])

  const activeJobsOptions = activeJobs ? activeJobs.map(lo => ({
    key: lo.jobId, id: 'jobId', value: lo.jobId, text: `${lo.name} (${lo.location.name})`
  })) : null

  const handleOnChange = (e) => {
    const { id, value } = e.target
    setData(prevData => ({
        ...prevData,
        [id]: value
    }))
  }

  const handleOnSelectChange = (e, { value, id }) => {
    setData(prevData => ({
      ...prevData,
      [id]: value
    }))
  }

  const handleOnSubmit = async () => {
    if(data) {
      let obj = removeEmptyStrings(data)
      let newErrors = {}
      let error = false
      if(!obj.hasOwnProperty('givenname')) {
        newErrors.givennameError = t('input-error-givenname')
        error = true
      }
      if(!obj.hasOwnProperty('surname')) {
        newErrors.surnameError = t('input-error-surname')
        error = true
      }
      if(!obj.hasOwnProperty('mail')) {
        newErrors.mailError = t('input-error-mail')
        error = true
      }
      if(!obj.hasOwnProperty('postalCode')) {
        newErrors.postalCodeError = t('input-error-postalCode')
        error = true
      }
      if(!obj.hasOwnProperty('location')) {
        newErrors.locationError = t('input-error-location')
        error = true
      }
      if(!obj.hasOwnProperty('streetAddress')) {
        newErrors.streetAddressError = t('input-error-streetAddress')
        error = true
      }
      if(!obj.hasOwnProperty('jobId')) {
        newErrors.jobIdError = t('input-error-jobId')
        error = true
      }
    
      setErrors({
        ...newErrors
      })
      if(error === false){
        var result = await insert_application(obj.jobId, obj)
        if(result.success === true)
          hide()
          navigate(`/applications/${result.data.applicationId}/candidates/${result.data.seekerId}`)
      }
    }
  }

  return(
    <>
      <Modal.Header>{t(`headline-new_application`)}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form>
            <Header size='small'>{t('label-applications')}</Header>
            <Form.Select
              fluid
              required
              id='jobId'
              label={t(`input-label-job`)}
              options={activeJobsOptions && activeJobsOptions}
              placeholder={t(`input-placeholder-job`)}
              onChange={handleOnSelectChange}
              error={errors.hasOwnProperty('jobIdError') && { content: errors.jobIdError }}
            />
            <Header size='small'>{t('label-profile')}</Header>
            <Form.Group widths='equal'>
              <Form.Input id='givenname' error={errors.hasOwnProperty('givennameError') && { content: errors.givennameError }} onChange={handleOnChange} required label={t(`input-label-givenname`)} placeholder={t(`input-placeholder-givenname`)} />
              <Form.Input id='surname' error={errors.hasOwnProperty('surnameError') && { content: errors.surnameError }} onChange={handleOnChange} required label={t(`input-label-surname`)} placeholder={t(`input-placeholder-surname`)} />
            </Form.Group>
            <Form.Input id='mail' error={errors.hasOwnProperty('mailError') && { content: errors.mailError }} onChange={handleOnChange} required label={t(`input-label-mail`)} placeholder={t(`input-placeholder-mail`)} />
            <Form.Input id='phone' onChange={handleOnChange} label={t(`input-label-phone`)} placeholder={t(`input-placeholder-phone`)} />
            <Header size='small'>{t('label-location')}</Header>
            <Form.Group widths='equal'>
              <Form.Input id='postalCode' error={errors.hasOwnProperty('postalCodeError') && { content: errors.postalCodeError }} onChange={handleOnChange} required label={t(`input-label-postalCode`)} placeholder={t(`input-placeholder-postalCode`)} />
              <Form.Input id='location' error={errors.hasOwnProperty('locationError') && { content: errors.locationError }} onChange={handleOnChange} required label={t(`input-label-location`)} placeholder={t(`input-placeholder-location`)} />
            </Form.Group>
            <Form.Input id='streetAddress' error={errors.hasOwnProperty('streetAddressError') && { content: errors.streetAddressError }} onChange={handleOnChange} required label={t(`input-label-streetAddress`)} placeholder={t(`input-placeholder-streetAddress`)} />
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={hide}>
          <Icon name='times' />
          {t(`button-cancel`)}
        </Button>
        <Button onClick={handleOnSubmit} primary>
          <Icon name='check' />
          {t(`button-create`)}
        </Button>
      </Modal.Actions>
    </>
  )
}

export default NewApplication