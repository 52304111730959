import React from "react";
import { Grid, Segment, Header, Modal, Button, Label, Form, Icon } from 'semantic-ui-react';
import { useTranslation } from "react-i18next";
import { insert_jobInterview, delete_jobInterview } from '../../utils/requestHelper';
import { fetchJob } from '../../redux/actions';
import { useModal } from '../../hooks/useModal';

import jobConfig from '../../config/job.json';
import { useDispatch } from "react-redux";

const VacancieQuestions = ({data, show}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {show : showA, hide: hideA, RenderModal: RenderModalA} = useModal();

  if(!show)
    return null

  const handleOnAddItem = async (e, object ) => {
    if(e==='interview')
      await insert_jobInterview(data.jobId, object)
    dispatch(fetchJob(data.jobId))
  }

  const handleOnRemoveItem = async (e, { id }) => {
    if(e==='interview')
      await delete_jobInterview(data.jobId, id)
    dispatch(fetchJob(data.jobId))
  }

  return(
    <div>
      <Grid columns='equal' stackable >
        <Grid.Column width={16}>
          <Segment basic color='blue' padded='very'>
            <Grid columns={2}>
              <Grid.Column>
                <Header as='h3'>
                {t(`headline-question`)}
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Button primary animated='vertical' floated='right' onClick={()=>showA(true)}>
                  <Button.Content hidden>{t(`button-new`)}</Button.Content>
                  <Button.Content visible>
                    <Icon name='add' />
                  </Button.Content>
                </Button>
              </Grid.Column>
            </Grid>
            <Grid textAlign='left' divided='vertically'>
              {data.interview.map((element, index) => (
                renderInterview(index, handleOnRemoveItem, element, t)
              ))}
            </Grid>
          </Segment>
        </Grid.Column>
      </Grid>
      {modalA(RenderModalA, t, hideA, handleOnAddItem)}
    </div>
  )

}

export default VacancieQuestions

function modalA(RenderModalA, t, hideA, handleOnAddItem) {
  let values = {}
  const handleOnChange = (e) => {
    const { id, value } = e.target
    values = {
      ...values,
      [id]: value
    }
  }
  const handleOnSelectChange = (e, { value, id }) => {
    values = {
      ...values,
      [id]: value
    }
  }
  const handleOnSave = () => {
    handleOnAddItem('interview', values)
    hideA()
  }

  return <RenderModalA>
    <Modal.Header>{t(`headline-new_interview`)}</Modal.Header>
    <Modal.Content>
      <Modal.Description>
        <p>{t(`content-new_interview`)}</p>
        <Form>
          <Form.TextArea onChange={handleOnChange} rows={2} id='headline' label={t(`input-label-interview_headline`)} placeholder={t(`input-placeholder-interview_headline`)} />
          <Form.TextArea onChange={handleOnChange} rows={6} id='content' label={t(`input-label-interview_content`)} placeholder={t(`input-placeholder-interview_content`)} />
          <Form.Select
              fluid
              id='type'
              label={t(`input-label-interview_type`)}
              options={jobConfig.interviewType}
              defaultValue={values.type ? values.type : jobConfig.interviewType[0]}
              onChange={handleOnSelectChange}
          />
          <p>{t(`content-new_defaultValue`)}</p>
          <Form.TextArea onChange={handleOnChange} rows={2} id='defaultValue' label={t(`input-label-interview_defaultValue`)} placeholder={t(`input-placeholder-interview_defaultValue`)} />
        </Form>
      </Modal.Description>
    </Modal.Content>
    <Modal.Actions>
      <Button onClick={hideA}>
        <Icon name='times' />
        {t(`button-cancel`)}
      </Button>
      <Button primary onClick={handleOnSave}>
        <Icon name='check' />
        {t(`button-save`)}
      </Button>
    </Modal.Actions>
  </RenderModalA>;
}

function renderInterview(index, handleOnRemoveItem, element, t) {
  return <Grid.Row columns={1} key={`vacancies-row-interview-#${index}`}>
    <Grid.Column>
      <Segment basic raised key={`vacancies-item-interview-#${index}`}>
        <Button onClick={() => handleOnRemoveItem('interview', { id: element.id })} floated='right' circular icon='trash alternate' color='red' />
        <Label as='a' color='orange' tag>
          {t(`label-${element.type}`)}
        </Label>
        <Header as='h4' >{element.headline}</Header>
        <p>{element.content}</p>
        {(element.type === 'select' || element.type === 'select-multi' )&&
        <Label.Group color='blue'>
          {element.defaultValue && element.defaultValue.split(',').map((el, index) => (
            <Label key={`vacancies-item-interview-label-#${index}`}>{el}</Label>
          ))}
        </Label.Group>
        }
      </Segment>
    </Grid.Column>
  </Grid.Row>;
}