import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchUsers } from '../redux/actions';
import { Header, Smart} from '../components';
import Loading from '../components/loading';
import MessagePortal from '../components/portal';
import User from '../components/user';

const Layout = ({children}) => {

  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(fetchUsers())
  },[children])

  return (
    <main>
      <Loading />
      <MessagePortal />
      <Header />
      <Smart />
      <User />
      {children}
    </main>
  );
}

export default Layout;