import React from 'react';
import { Segment, Header, Pagination, Dropdown } from 'semantic-ui-react';
import VacanciesListItem from './vacanciesListItem';

import moment from 'moment';
import { jobOrderBy } from '../../config/config';
import { useTranslation } from 'react-i18next';

const VacanciesList = ({show, pagination, onClickEdit, onChangeOrderBy, onSetPagination, jobs, jobsOrderBy}) => {
  const {t} = useTranslation()

  if(!show)
    return null

  return(
    <section>
      <Header as='h2' attached='top' className={'background-blue'} style={{display: 'flex'}}>
        <Pagination
          activePage={pagination.activePage}
          onPageChange={onSetPagination}
          firstItem={null}
          lastItem={null}
          pointing
          secondary
          inverted
          style={{borderColor: 'transparent'}}
          totalPages={pagination.totalPages}
        />
        <Dropdown 
          text={t('input-label-sortby', {sortby: t(`label-${jobsOrderBy}`)})}
          style={{marginRight: 0, marginLeft: 'auto'}}
        >
          <Dropdown.Menu>
            {jobOrderBy.map(element => (
              <Dropdown.Item key={element.key} value={element.value} text={t(element.text)} onClick={onChangeOrderBy}/>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Header>
      <Segment attached >
        {jobs.map((element, index) => (
          renderVacancieList(index, element, onClickEdit)
        ))
        }
      </Segment>
    </section>
  )
}

export default VacanciesList

function renderVacancieList(index, element, onClickEdit) {
  return <VacanciesListItem
    key={index}
    jobId={element.jobId}
    name={element.name}
    department={element.department}
    entryLevel={element.entryLevel}
    subscription={element.subscription}
    isActive={element.isActive}
    createdAt={moment(element.createdAt).format('DD.MM.YYYY')}
    modifiedAt={moment(element.modifiedAt).format('DD.MM.YYYY')}
    visibility={element.visibility}
    locality={element.location && element.location.name}
    streetAddress={element.location && element.location.street_address}
    postalCode={element.location && element.location.postal_code}
    location={element.location && element.location.location}
    onClickEdit={onClickEdit} />;
}
