import { LOCATIONS_FETCH, NETWORK_START, NETWORK_ERROR, NETWORK_STOP } from "../constants/action-types";
import { fetch_locations } from "../../utils/requestHelper";

export function fetchLocations(){
  return async (dispatch) => {
    try {
      dispatch({ type: NETWORK_START });
      const result = await fetch_locations()
      if(result.success === true){
        dispatch({ type: LOCATIONS_FETCH, result: result.data })
      }
      dispatch({ type: NETWORK_STOP });
    } catch (error) {
      dispatch({ type: NETWORK_STOP });
      dispatch({ type: NETWORK_ERROR, error });
    }
  };
}