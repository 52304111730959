import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from '../../redux/actions/';
import { Menu, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { logout } from '../../utils/common';
import MenuItem from './menuItem';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const me = useSelector((state)=>state.me);
  const headerMenu = useSelector((state) => state.headerMenu);
  const notifications = useSelector((state) => state.notifications);
  const [ info, setInfo ] = useState(false);

  useEffect(()=>{
    if(notifications){
      if(notifications.length > 0)
        setInfo(true)
      else
        setInfo(false)
    }
    else
      setInfo(false)

  },[notifications])

  const handleItemClick = (e) => {
    navigate(`/${e}`)
  }
  return(
    <Menu pointing secondary stackable size='huge' style={{borderBottom: 0}} >
      {me && headerMenu.map((element,index) => {
        if(element.permission.includes(me.permission))
          return (<MenuItem
            key={`header-menu-#${index}`}
            name={t(`header-menu-item-${element.name}`)} 
            component={element.component} 
            activeItem={window.location.pathname}
            handleItemClick={handleItemClick} 
          />)
      })}
      <Menu.Menu position='right'>
        <Menu.Item
          name={t('button-logout')}
          active={'/logout' === window.location.pathname}
          onClick={logout}
        />
        <Menu.Item
          name='user'
          onClick={()=>dispatch(toggleSidebar())}
        >
          <Icon.Group size='large' style={{transform: 'translate(0px, 10%)'}}>
            <Icon name='user circle outline' />
            {info === true && <Icon corner name='bell' color='red' />}
          </Icon.Group>
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  )
}

export default Header