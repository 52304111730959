import React, { useState, useEffect } from 'react';
import { Grid, Segment, Icon, Header, Button, Modal, Form, Image, Pagination, Dropdown, Popup } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useModal } from '../../hooks/useModal';
import moment from 'moment';

import defaultUser from './images/defaultUser.jpg'
import defaultUserGray from './images/defaultUserGray.jpg'
import { delete_appointments, insert_appointments } from '../../utils/requestHelper';
import { useDispatch } from 'react-redux';
import { fetchAppointments } from '../../redux/actions';

const Appointments = ({show, seekerMail, appointments, appointmentsCount, applicationId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const locations = useSelector((state)=>state.locations);
  const users = useSelector((state)=>state.users);

  const userOptions = users.map((lo => ({
    key: lo.mail, value: lo.mail, text: lo.mail
  })))

  const locationOptions = locations.map((lo => ({
    key: lo.id, value: lo.id, text: lo.name
  })))

  const {show : showModal, hide, RenderModal} = useModal();
  const itemsPerPage = 4
  const [ pagination, setPagination ] = useState({defaultActivePage: 1, totalPages: 1, activePage: 1 });

  useEffect(()=>{
    if(appointmentsCount > itemsPerPage)
      setPagination(prevData => ({
        ...prevData,
        totalPages: Math.ceil(appointmentsCount / itemsPerPage)
      }))
  },[appointmentsCount])

  if(!show)
    return null

  const handleOnDeleteAppointment = async (appointmentId) => {
    let result = await delete_appointments(applicationId, appointmentId)
    if(result.success === true)
      dispatch(fetchAppointments(applicationId, null, null))
  }

  const handleOnClickSubmit = async (values) => {
    let startDateTime = new moment.utc(new moment(`${values.date} ${values.startDateTime}`, "YYYY-MM-DD HH:mm"));
    let endDateTime = new moment.utc(new moment(`${values.date} ${values.endDateTime}`, "YYYY-MM-DD HH:mm"));
    hide()
    let result = await insert_appointments(applicationId,{startDateTime:startDateTime, endDateTime:endDateTime, subject: values.subject, locationId: values.locationId || null, message: values.message, attendees: values.attendees})
    if(result.success === true)
      dispatch(fetchAppointments(applicationId, null, null))
  }

  const handleOnSetPagination = (e, { activePage }) => {

    let multi = itemsPerPage * (activePage -1)
    if(activePage === 1)
      multi = 0
      
    dispatch(fetchAppointments(applicationId, itemsPerPage, multi))
    setPagination(prevData => ({
      ...prevData,
      activePage: activePage,
      totalPages: Math.ceil(appointmentsCount / itemsPerPage)
    }))
  }

  return(
    <Segment basic color='blue' padded='very' textAlign='left'>
      <Grid columns={2}>
        <Grid.Column>
          <Header as='h3'>
            {t(`headline-appointments`)}
          </Header>
        </Grid.Column>
        <Grid.Column>
          <Button primary animated='vertical' onClick={()=>showModal(true)} floated='right'>
            <Button.Content hidden>{t(`button-new`)}</Button.Content>
            <Button.Content visible>
              <Icon name='add to calendar' />
            </Button.Content>
          </Button>
        </Grid.Column>
      </Grid>
      <Grid textAlign='left' columns={1}>
      {appointments.length > 0 ? appointments.slice(0).reverse().map((element, index)=> (
        renderAppointments(index, element, handleOnDeleteAppointment, t)
      )) :
        <Grid.Column textAlign='center' key={`attachment-#-1`}>
          <Header as='h2' icon>
            <Icon name='calendar alternate outline' circular inverted color='blue' />
              {t('headline-no_appointments')}
            <Header.Subheader>
              {t('content-no_appointments')}
            </Header.Subheader>
          </Header>
        </Grid.Column>
      }
        <Grid.Column textAlign='right'>
          {pagination.totalPages > 1 && <Pagination
            activePage={pagination.activePage}
            onPageChange={handleOnSetPagination}
            firstItem={null}
            lastItem={null}
            pointing
            secondary
            totalPages={pagination.totalPages}
          />}
        </Grid.Column>
      </Grid>
      {appointmentModal(RenderModal, t, seekerMail, userOptions, locationOptions, hide, handleOnClickSubmit)}
    </Segment>
  )
}

export default Appointments

function appointmentModal(RenderModal, t, seekerMail, userOptions, locationOptions, hide, handleOnClickSubmit) {
  let defaultAttendees = [...userOptions, {key:seekerMail, value: seekerMail, text: seekerMail}]
  let values = {attendees:[]}
  const handleOnChange = (e) => {
    const { id, value } = e.target
    values = {
      ...values,
      [id]: value.replace(/\n\r?/g, '<br />')
    }
  }
  const handleOnSelect = (e, { value, id }) => {
    if(value === '')
      delete values[id]
    else
      values = {
        ...values,
        [id]: value
      }
  }
  const handleOnAddAttendee = (e, { value, id }) => {
    if(value.indexOf('@') > 0 && value.indexOf('.'))
      defaultAttendees.push({ key: value, value: value, text: value })
  }
  const handleOnSubmit = () => {
    let error = false
    if(values.attendees.length < 1){
      error = true
    }
    if(!values.subject){
      error = true
    }
    if(!values.date){
      error = true
    }
    if(!values.startDateTime){
      error = true
    }
    if(!values.endDateTime){
      error = true
    }
    if(!error)
      handleOnClickSubmit(values)
  }
  return <RenderModal>
    <Modal.Header>{t(`headline-new_appointment`)}</Modal.Header>
    <Modal.Content>
      <Modal.Description>
        <Header>{t(`headline-calendar_appointment`)}</Header>
        <Form>
          <Form.Input id='subject' required onChange={handleOnChange} label={t(`input-label-subject`)} placeholder={t(`input-placeholder-subject`)} />
          <div className='required field'>
            <label>{t(`input-label-attendees`)}</label>
            <Dropdown
              as='Form'
              style={{marginBottom: '15px'}}
              id='attendees'
              placeholder={t(`input-placeholder-attendees`)}
              search
              selection
              fluid
              multiple
              allowAdditions
              options={defaultAttendees}
              onAddItem={handleOnAddAttendee}
              onChange={handleOnSelect}
            />
          </div>
          <Form.Group widths='equal'>
            <Form.Input type='date' required onChange={handleOnChange} id='date' label={t(`input-label-date`)} placeholder={t(`input-placeholder-date`)} />
            <Form.Input type='time' required onChange={handleOnChange} id='startDateTime' label={t(`input-label-startTime`)} placeholder={t(`input-placeholder-startTime`)} />
            <Form.Input type='time' required onChange={handleOnChange} id='endDateTime' label={t(`input-label-endTime`)} placeholder={t(`input-placeholder-endTime`)} />
          </Form.Group>
          <Dropdown style={{marginBottom: '15px'}} onChange={handleOnSelect} as='Form' fluid clearable id='locationId' placeholder={t(`input-label-location`)} options={locationOptions} selection />
          <Form.TextArea rows={12} onChange={handleOnChange} id='message' label={t(`input-label-description`)} placeholder={t(`input-label-description`)} />
        </Form>
      </Modal.Description>
    </Modal.Content>
    <Modal.Actions>
      <Button onClick={hide}>
        <Icon name='times' />
        {t(`button-cancel`)}
      </Button>
      <Button primary onClick={handleOnSubmit}>
        <Icon name='check' />
        {t(`button-create_appointment`)}
      </Button>
    </Modal.Actions>
  </RenderModal>;
}

function renderAppointments(index, element, handleOnDeleteAppointment, t) {
  const styles = {
    avatars: {
      direction: 'rtl',
      textAlign: 'left',
      paddingLeft: 20
    },
    avatar: {
      position: 'relative',
      left: -5,
      marginLeft: -15,
      zIndex: 1,
    }
  }
  return <Grid.Column key={`attachment-#${index}`}>
    <Segment raised onClick={null} style={element.status === 'CANCELLED' || moment(element.startDateTime).isBefore() ? {background: '#f9fafb', opacity: '0.3'} : {background: '#f9fafb'}}>
      <Grid columns={3} divided>
        <Grid.Row>
          <Grid.Column width={3} verticalAlign='middle'>
            <Header as='h2' textAlign='center'>
              {moment(element.startDateTime).format('DD')}
              <Header.Subheader>
                {moment(element.startDateTime).format('MMM')}
              </Header.Subheader>
              <Header.Subheader>
                {moment(element.startDateTime).format('YYYY')}
              </Header.Subheader>
            </Header>
          </Grid.Column>
          <Grid.Column width={13}>
            <Header as='h3' textAlign='left'>
              {element.subject}
              {element.status === 'CANCELLED' && ` (${t(element.status)})`}
              <Header.Subheader>
                <Icon name='clock outline' /> {`${moment(element.startDateTime).format('HH:mm')} - ${moment(element.endDateTime).format('HH:mm')}`}
              </Header.Subheader>
            </Header>
            <div>
              <Image src={defaultUser} avatar circular />
              <span>{element.organizer && `${element.organizer.givenname} ${element.organizer.surname}`}</span>
              <Button disabled={element.status === 'CANCELLED' || moment(element.startDateTime).isBefore()} onClick={()=>handleOnDeleteAppointment(element.appointmentId)} floated='right'>{t(`button-delete`)}</Button>
            </div>
            <div>
              <div style={styles.avatars}>
                {element.attendees && element.attendees.map(el => (
                  <span style={styles.avatar}>
                    <Popup
                      content={el.address}
                      trigger={<Image src={defaultUserGray} avatar circular />}
                    />
                  </span>
                ))}
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  </Grid.Column>;
}
