import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Portal, Segment, Button, Header } from 'semantic-ui-react';
import { hidePortal } from "../../redux/actions";


const MessagePortal = () => {
  const {t} = useTranslation()
  const portal = useSelector((state) => state.portal)
  const dispatch = useDispatch()
  if (!portal) 
    return null
  
  return (
    <Portal onClose={()=>dispatch(hidePortal())} open={true}>
      <Segment
        style={{
          left: '40%',
          position: 'fixed',
          top: '50%',
          zIndex: 1000,
        }}
      >
        <Header>{portal.header}</Header>
        <p>{portal.message}</p>
        <Button
          content={t('button-close')}
          negative={portal.error}
          primary={!portal.error}
          onClick={()=>dispatch(hidePortal())}
        />
      </Segment>
    </Portal>
  )
}

export default MessagePortal;