import { COMMENTS_FETCH, SEEKER_FETCH, NETWORK_START, NETWORK_ERROR, NETWORK_STOP } from "../constants/action-types";
import { fetch_seeker, fetch_comments } from "../../utils/requestHelper";

export function fetchSeeker(seekerId){
  return async (dispatch) => {
    try {
      dispatch({ type: NETWORK_START });
      const result = await fetch_seeker(seekerId)
      if(result.success === true){
        dispatch({ type: SEEKER_FETCH, result: result.data })
      }
      dispatch({ type: NETWORK_STOP });
    } catch (error) {
      dispatch({ type: NETWORK_STOP });
      dispatch({ type: NETWORK_ERROR, error });
    }
  };
}

export function fetchComments(seekerId, limit, skip){
  return async (dispatch) => {
    try {
      dispatch({ type: NETWORK_START });
      const result = await fetch_comments(seekerId, limit, skip)
      if(result.success === true){
        dispatch({ type: COMMENTS_FETCH, result: result.data, count: result.count })
      }
      dispatch({ type: NETWORK_STOP });
    } catch (error) {
      dispatch({ type: NETWORK_STOP });
      dispatch({ type: NETWORK_ERROR, error });
    }
  };
}