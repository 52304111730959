import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Segment, Header, Statistic } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const VacanciesStatistics = ({ apply, statistics, show }) => {
  const { t } = useTranslation();
  const applicationStatus = useSelector((state)=>state.config_applications_state)

  if (!show)
    return null

  return (
    <div>
      <Grid columns='equal' stackable >
        <Grid.Column width={10}>
          <Segment basic color='blue' padded='very'>
            <Header as='h3'>
              {t(`headline-information`)}
            </Header>
            <Grid columns={2} >
              {renderApplicationStatistics(statistics, t, applicationStatus)}
            </Grid>
          </Segment>
        </Grid.Column>
        <Grid.Column>
          <Segment basic color='blue' padded='very'>
            <Header as='h3'>
              {t(`headline-jobs_apply`)}
            </Header>
            <Grid.Row columns={1} centered textAlign='center'>
              {renderApplyStatistics(apply, t)}
            </Grid.Row>
          </Segment>
        </Grid.Column>
      </Grid>
    </div>
  )
}

export default VacanciesStatistics

function renderApplicationStatistics(statistics, t, applicationStatus) {
  return statistics && statistics.map((element, index) => {
    return (
      <Grid.Column key={`job-application-statistics-#${index}`}>
        <Segment padded color={applicationStatus.find(x => x.key === element.status).color}>
          <Statistic color={applicationStatus.find(x => x.key === element.status).color}>
            <Statistic.Value style={{ textAlign: 'left' }}>{element.counter}</Statistic.Value>
            <Statistic.Label style={{ textAlign: 'left' }}>{t(applicationStatus.find(x => x.key === element.status).label)}</Statistic.Label>
          </Statistic>
        </Segment>
      </Grid.Column>
    );
  });
}

function renderApplyStatistics(apply, t) {
  return apply && apply.map((element, index) => {
    return (
      <Grid.Column key={`job-statistics-apply-#${index}`}>
        <Segment placeholder style={{ margin: 15 }}>
          <Statistic>
            <Statistic.Label>{t(`label-statistics-${element.type}`)}</Statistic.Label>
            <Statistic.Value>{element.counter}</Statistic.Value>
          </Statistic>
        </Segment>
      </Grid.Column>);
  });
}
