import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Menu, Form, Segment } from 'semantic-ui-react';


const UserMenu = ({ show, onSelect }) => {
  const {t} = useTranslation()
  const [ item, setItem ] = useState('messages')

  if(!show)
    return null

  const handleOnChange = (e, { value }) => {
    setItem(value)
    onSelect(value)
  }
    
  return (
    <Grid centered columns='equal'>
      <Grid.Row>
        <Menu pointing secondary position='center'>
          <Menu.Item
            name={t(`label-notification`)}
            value='messages'
            active={item === 'messages'}
            onClick={handleOnChange}
          />
          <Menu.Item
            name={t(`label-profile`)}
            value='edit'
            active={item === 'edit'}
            onClick={handleOnChange}
          />
        </Menu>
      </Grid.Row>
    </Grid>
  )
}

export default UserMenu;