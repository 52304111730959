import React, { useState, useEffect } from 'react';
import { Grid, Header, Icon, Button, Label } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { fetch_job_applications_count } from '../../utils/requestHelper';
import moment from 'moment';

const VacanciesListItem = (props) => {
  const { t } = useTranslation();
  const [ applicationCount, setApplicationCount ] = useState();

  useEffect(()=>{
    const fetchData = async () => {
      var data = await fetch_job_applications_count(props.jobId)
      if(data.success === true)
        setApplicationCount(data.data)
    }
    if(props.jobId)
      fetchData()
  },[])

  const renderCountColor = (value) => {
    if(value < 3)
      return "orange"
    else if(value >= 3 && value < 6)
      return "yellow"
    else if(value >= 6 && value < 10)
      return "olive"
    else if(value >= 10)
      return "green"
    else return null
  }

  const isActive = {
    0: { label : t('label-in_progress'), color: "grey"},
    1: { label : t('label-published'), color: "green"},
    'undefined': { label : "unbekannt", color: null}
  }

  return(
    <Grid columns='equal' stackable verticalAlign='middle' key={`vacancie-list-itzem-${props.jobId}`}>
      <Grid.Column>
        {new moment(new Date).diff(new moment(props.createdAt, "DD.MM.YYYY"), 'days') < 8 && <Label as='a' color='red' ribbon>
          <Icon name='info'/>
          {t('label-new')}
        </Label>}
        <Header as='h2'>{props.name}</Header>
        <Label>
          <Icon name='time'/> {props.subscription}
        </Label>
        <Label>
          <Icon name='briefcase'/> {props.department}
        </Label>
        <Label>
          <Icon name='tag'/> {props.entryLevel}
        </Label>
      </Grid.Column>
      <Grid.Column>
        <Grid columns={1} verticalAlign='middle' style={{margin: 'auto'}}>
          <Grid.Column>
            <Label size='medium' color={isActive[props.isActive].color}>
              {isActive[props.isActive].label}
            </Label>
          </Grid.Column>
          {applicationCount && <Grid.Column>
            <Label size='medium' color={renderCountColor(applicationCount.count)}>
              {`${applicationCount.count} ${t('label-seeker')}`}
            </Label>
          </Grid.Column>}
        </Grid>
      </Grid.Column>
      <Grid.Column>
        <Header style={{fontSize: '1.4rem'}}>{t('label-location')}</Header>
        <p>
          {props.locality}<br/>
          {props.streetAddress}<br/>
          {props.postalCode}, {props.location}<br/>
        </p>
      </Grid.Column>
      <Grid.Column>
        <Button.Group floated='right'>
          <Button primary size='medium' onClick={()=>props.onClickEdit({jobId: props.jobId, name: props.name})}><Icon name='edit' />{t('button-detail')}</Button>
        </Button.Group>
      </Grid.Column>
    </Grid>
  )
}

export default VacanciesListItem