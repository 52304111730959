import React, { useState } from 'react';
import { Grid, Segment, Header, Button, Icon, Modal, Form, Dropdown} from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useModal } from '../../hooks/useModal';

import defaultUser from './images/defaultUser.jpg'
import { updateApplication } from '../../redux/actions';
import { fetch_active_jobs } from '../../utils/requestHelper';

const Application = ({show, applicationId, status, job, organizer, createdAt, modifiedAt}) => {
  const { t } = useTranslation();
  const users = useSelector((state)=>state.users);
  const applicationStatus = useSelector((state)=>state.config_applications_state)
  const dispatch = useDispatch();
  const [ activeJobs, setActiveJobs ] = useState()
  const {show : showC, hide: hideC, RenderModal: RenderModalC} = useModal();

  const style = {
    wordBreak: {
      wordBreak: "break-word"
    },
    uppercase: {
      textTransform: "uppercase"
    }
  }

  if(!show)
    return null

  const userOptions = users.map((lo => ({
    key: lo.guid, value: lo.guid, text: `${lo.givenname} ${lo.surname}`, image:  { avatar: true, src: defaultUser }
  })))

  const applicationStatusOptions = Object.keys(applicationStatus).map(((key, index) => ({
    key: applicationStatus[index].key, value: applicationStatus[index].key, text: t(applicationStatus[index].label), disabled: applicationStatus[index].disabled
  })))

  const activeJobsOptions = activeJobs ? activeJobs.map(lo => ({
    key: lo.jobId, id: 'jobId', value: lo.jobId, text: `${lo.name} (${lo.location.name})`
  })) : null

  const handleOnChangeState = async (values) => {
    dispatch(updateApplication(applicationId, values))
  }

  const handleOnChangeDropDown = (e, { value, id }) => {
    var values = {
      [id]: value
    }
    handleOnChangeState(values)
  }

  const handleOnOpenModalC = async () => {
    let data = await fetch_active_jobs()
    if(data.success == true)
      setActiveJobs(data.data)
    showC(true)
  }

  return(
    <Segment basic color='blue' padded='very' textAlign='left'>
      <Grid columns={2}>
        <Grid.Column>
          <Header as='h3'>
            {job.name}
            <Header.Subheader>
              {job.department}
            </Header.Subheader>
          </Header>
        </Grid.Column>
        <Grid.Column>
          <Button primary animated='vertical' floated='right' onClick={handleOnOpenModalC}>
            <Button.Content hidden>{t(`button-next`)}</Button.Content>
            <Button.Content visible>
              <Icon name='edit' />
            </Button.Content>
          </Button>
        </Grid.Column>
      </Grid>
      <Grid textAlign='left' columns={2}>
        <Grid.Row>
          <Grid.Column>
            <Header as='h4' style={{...style.uppercase}}>
              {t('label-job_identifier')}
              <Header.Subheader style={{...style.wordBreak, ...style.uppercase}}>
                {job.identifier}
              </Header.Subheader>
            </Header>
          </Grid.Column>
          <Grid.Column>
            <Header as='h4' style={{...style.uppercase}}>
              {t('label-organizer')}
              <Header.Subheader style={{...style.wordBreak, ...style.uppercase}}>
                {organizer && 
                  <div className={'sub header'}>
                    <Dropdown
                      id='organizerId'
                      style={{marginTop:10}}
                      inline
                      options={userOptions}
                      defaultValue={organizer && userOptions.find(x => x.key === organizer.guid).value}
                      onChange={handleOnChangeDropDown}
                    />
                  </div>
                }
              </Header.Subheader>
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Header as='h4' style={{...style.uppercase}}>
              {t('label-createdAt')}
              <Header.Subheader style={{...style.wordBreak, ...style.uppercase}}>
                {createdAt}
              </Header.Subheader>
            </Header>
          </Grid.Column>
          <Grid.Column>
            <Header as='h4' style={{...style.uppercase}}>
              {t('label-modifiedAt')}
              <Header.Subheader style={{...style.wordBreak, ...style.uppercase}}>
                {modifiedAt}
              </Header.Subheader>
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid>
        <Grid.Column>
          <Header as='h4' style={{...style.uppercase}}>
            {t('label-status')}
            <Header.Subheader style={{...style.wordBreak, ...style.uppercase}}>
              {
                <div className={'sub header'}>
                  <Button.Group color={applicationStatus.find(x => x.key === status).color}>
                    <Button style={{cursor: 'default', pointerEvents: 'none'}} >{t(applicationStatus.find(x => x.key === status).label)}</Button>
                    <Dropdown 
                      className='button icon'
                      floating
                      options={applicationStatusOptions}
                      defaultValue={applicationStatusOptions.find(x => x.key === status).value}
                      closeOnChange
                      trigger={<></>}
                      id='status'
                      onChange={handleOnChangeDropDown}
                    />
                  </Button.Group>
                </div>
              }
            </Header.Subheader>
          </Header>
        </Grid.Column>
      </Grid>
      {modalC(RenderModalC, t, hideC, handleOnChangeState, userOptions, applicationStatusOptions, activeJobsOptions)}
    </Segment>
  )
}

export default Application

function modalC(RenderModalC, t, hideC, handleOnChangeState, userOptions, applicationStatusOptions, activeJobsOptions) {
  let values = {}

  const handleOnChange = (e, { value, id }) => {
    values = {
      ...values,
      [id]: value
    }
  }
  const handleOnSave = () => {
    handleOnChangeState(values)
    hideC()
  }
  return <RenderModalC>
    <Modal.Header>{t(`headline-next_step`)}</Modal.Header>
    <Modal.Content>
      <Modal.Description>
        <p>{t(`content-next_step`)}</p>
        <Form>
          {activeJobsOptions && <Form.Group style={{marginLeft: 0, marginRight: 0 }} >
            <Dropdown
              placeholder={t(`input-placeholder-job`)}
              id='jobId'
              fluid
              selection
              options={activeJobsOptions}
              onChange={handleOnChange}
            />
          </Form.Group>}
          <Form.Group widths='equal' style={{marginLeft: 0, marginRight: 0 }}>
            <Dropdown
              placeholder={t(`input-label-organizer`)}
              id='organizerId'
              fluid
              selection
              options={userOptions}
              onChange={handleOnChange}
              style={{marginRight: '20px'}}
            />
            <Dropdown
              placeholder={t(`input-label-status`)}
              id='status'
              fluid
              selection
              options={applicationStatusOptions}
              onChange={handleOnChange}
              style={{marginLeft: '20px'}}
            />
          </Form.Group>
          <Form.TextArea rows={12} onChange={handleOnChange} id='notification' label={t(`input-label-notification`)} placeholder={t(`input-label-notification`)} />
        </Form>
      </Modal.Description>
    </Modal.Content>
    <Modal.Actions>
      <Button onClick={hideC}>
        <Icon name='times' />
        {t(`button-cancel`)}
      </Button>
      <Button primary onClick={handleOnSave}>
        <Icon name='check' />
        {t(`button-save`)}
      </Button>
    </Modal.Actions>
  </RenderModalC>;
}