export const applicationOrderBy = [
  { text: 'label-status', value: 'status', key: 'applicationOrderBy-status'},
  { text: 'label-seeker', value: 'seeker', key: 'applicationOrderBy-seeker'},
  { text: 'label-createdAt', value: 'createdAt', key: 'applicationOrderBy-createdAt'},
  { text: 'label-modifiedAt', value: 'modifiedAt', key: 'applicationOrderBy-modifiedAt'}
]

export const jobOrderBy = [
  { text: 'label-name', value: 'name', key: 'jobOrderBy-name'},
  { text: 'label-department', value: 'department', key: 'jobOrderBy-department'},
  { text: 'label-entryLevel', value: 'entryLevel', key: 'jobOrderBy-entryLevel'},
  { text: 'label-subscription', value: 'subscription', key: 'jobOrderBy-subscription'},
  { text: 'label-createdAt', value: 'createdAt', key: 'jobOrderBy-createdAt'},
  { text: 'label-modifiedAt', value: 'modifiedAt', key: 'jobOrderBy-modifiedAt'}
]