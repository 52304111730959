import React, { useEffect, useState } from 'react';
import { Grid, Segment, Label, Header, Icon, Progress, Pagination } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchApplications } from '../../redux/actions';

const Applications = ({show, applications, applicationsCount, seekerId}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const applicationStatus = useSelector((state)=>state.config_applications_state)
  const itemsPerPage = 5
  const [ pagination, setPagination ] = useState({defaultActivePage: 1, totalPages: 1, activePage: 1 });

  useEffect(()=>{
    setPagination(prevData => ({
      ...prevData,
      totalPages: Math.ceil(applicationsCount / itemsPerPage)
    }))
  },[applicationsCount])

  if(!show)
    return null

  const handleOnSetPagination = (e, { activePage }) => {

    let multi = itemsPerPage * (activePage -1)
    if(activePage === 1)
      multi = 0

    dispatch(fetchApplications({seekerId: seekerId}, itemsPerPage, multi ))
    setPagination(prevData => ({
      ...prevData,
      activePage: activePage,
      totalPages: Math.ceil(applicationsCount / itemsPerPage)
    }))
  }

  return(
    <Segment basic color='blue' padded='very' textAlign='left'>
      <Grid columns={2}>
        <Grid.Column>
          <Header as='h3'>
            {t(`headline-seeker_applications`)}
          </Header>
        </Grid.Column>
        <Grid.Column textAlign='right'>
          {pagination.totalPages > 1 && <Pagination
            activePage={pagination.activePage}
            onPageChange={handleOnSetPagination}
            firstItem={null}
            lastItem={null}
            pointing
            secondary
            totalPages={pagination.totalPages}
          />}
        </Grid.Column>
      </Grid>
      {applications.map((element,index)=>(
        renderApplications(index, element, t, applicationStatus)
      ))}
    </Segment>
  )
}

export default Applications

function renderApplications(index, element, t, applicationStatus) {
  return <Grid key={`applications-#${index}`} columns='equal' stackable verticalAlign='middle'>
    <Grid.Column>
      <Header as='h2'>{element.job.name}</Header>
      <Label>
        <Icon name='time' /> {element.job.subscription}
      </Label>
      <Label>
        <Icon name='briefcase' /> {element.job.department}
      </Label>
      <Label>
        <Icon name='tag' /> {element.job.entryLevel}
      </Label>
    </Grid.Column>
    <Grid.Column>
      <Progress percent={applicationStatus.find(x => x.key === element.status).percent} color={applicationStatus.find(x => x.key === element.status).color}>{t(applicationStatus.find(x => x.key === element.status).label)}</Progress>
    </Grid.Column>
  </Grid>;
}
