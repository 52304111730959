import { TOGGLE_SIDEBAR, LOADING_START, LOADING_STOP, SMART_SET, SMART_UPDATE, PORTAL_SHOW, PORTAL_HIDE } from "../constants/action-types";

export function startLoading() {
  return (dispatch) => {
    dispatch({type: LOADING_START})
  }
}

export function stopLoading() {
  return (dispatch) => {
    dispatch({type: LOADING_STOP})
  }
}

export function setSmartMenu(object){
  return (dispatch) => {
    dispatch({type: SMART_SET, smartMenu: object})
  }
}

export function updateSmartMenu(object){
  return (dispatch) => {
    dispatch({type: SMART_UPDATE, smartMenu: object})
  }
}

export function toggleSidebar(){
  return (dispatch) => {
    dispatch({type:TOGGLE_SIDEBAR})
  }
}

export function showPortal(object){
  return (dispatch) => {
    dispatch({type: PORTAL_SHOW, result: object})
  }
}

export function hidePortal(){
  return (dispatch) => {
    dispatch({type: PORTAL_HIDE})
  }
}