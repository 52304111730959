import React from 'react';
import { Menu } from 'semantic-ui-react';

const MenuItem = (props) => {

  return(
    <Menu.Item
      name={props.name}
      active={props.activeItem.startsWith(`/${props.component}`)}
      onClick={()=>props.handleItemClick(props.component)}
    >
      {props.name}
    </Menu.Item>
  )
}

export default MenuItem