import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SearchBar } from '../components';
import { useTranslation } from 'react-i18next';
import { setSmartMenu, fetchJobs, updateSmartMenu, setJobsOrderBy, fetchMe, setJobsFilter, setJobsPagination } from '../redux/actions';
import { useModal } from '../hooks/useModal';
import NewVacancie from '../components/modal/newVacancie';
import NewApplication from '../components/modal/newApplication';
import { useNavigate } from 'react-router-dom';
import VacanciesList from '../components/vacancies/vacanciesList';

const Job = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const itemsPerPage = 10
  const me = useSelector((state)=>state.me);
  const jobs = useSelector((state)=>state.jobs);
  const jobsCount = useSelector((state)=>state.jobsCount);
  const jobsOrderBy = useSelector((state)=>state.jobsOrderBy);
  const filter = useSelector((state)=>state.jobsFilter);
  const pagination = useSelector((state)=>state.jobsPagination)

  const {show : showA, hide: hideA, RenderModal: RenderModalA} = useModal();
  const {show : showB, hide: hideB, RenderModal: RenderModalB} = useModal();

  useEffect(()=>{
    handleOnSetPagination(null, {activePage: pagination.activePage || 0})
    dispatch(fetchMe())
    dispatch(setSmartMenu({
      buttons: [
        { key: 'add_candidate', name: 'button-add_candidate', icon: 'add', onButtonClick: ()=>showB(true)},
        { key: 'add_job', name: 'button-add_job', icon: 'add', onButtonClick: ()=>showA(true)}
      ],
      navigation: null
    }))
  },[props])

  useEffect(()=>{
    if(me)
      dispatch(updateSmartMenu({
        headline: t('headline-welcome', {givenname: me.givenname})
      }))
  },[me])

  useEffect(()=>{
    dispatch(setJobsPagination({
      ...pagination,
      totalPages: Math.ceil(jobsCount / itemsPerPage)
    }))
  },[jobsCount])

  useEffect(()=>{
    let multi = itemsPerPage * (pagination.activePage -1)
    if(pagination.activePage === 1)
      multi = 0
    dispatch(fetchJobs(filter, jobsOrderBy, itemsPerPage, multi ))
  },[jobsOrderBy])

  const handleOnFilterJobs = async(values) => {
    dispatch(setJobsFilter(values))
    dispatch(fetchJobs(values, jobsOrderBy, itemsPerPage, 0))
  }

  const handleOnClickJobDetails = async (target) => {
    navigate(`/jobs/${target.jobId}`)
  }

  const handleOnChangeOrderBy = (e, { value }) => {
    dispatch(setJobsOrderBy(value))
  }

  const handleOnSetPagination = (e, { activePage }) => {
    let multi = itemsPerPage * (activePage -1)
    if(activePage === 1)
      multi = 0
    dispatch(fetchJobs(filter, jobsOrderBy, itemsPerPage, multi ))
    dispatch(setJobsPagination({
      ...pagination,
      activePage: activePage
    }))
  }

  return (
    <article>
      <SearchBar
        show={true}
        currentFilter={filter}
        onSearch={handleOnFilterJobs}
        user={me}
      />
      <VacanciesList
        show={true}
        onClickEdit={handleOnClickJobDetails}
        onChangeOrderBy={handleOnChangeOrderBy}
        onSetPagination={handleOnSetPagination}
        jobsOrderBy={jobsOrderBy}
        pagination={pagination}
        jobs={jobs}
      />
      <RenderModalA>
        <NewVacancie
          hide={hideA}
        />
      </RenderModalA>
      <RenderModalB>
        <NewApplication
          hide={hideB}
        />
      </RenderModalB>
    </article>
  );
}

export default Job;