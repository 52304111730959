import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Header, Image, Sidebar, Segment, Button, Icon } from 'semantic-ui-react';
import { fetchNotifications, toggleSidebar, updateMe } from "../../redux/actions";
import Basic from "./basic";
import UserMenu from './menu'
import Messages from "./messages";

import user_image from './images/defaultUser.jpg'
import { delete_notification } from "../../utils/requestHelper";

const User = ({  }) => {
  const [ show, setShow ] = useState('messages')
  const sidebar = useSelector((state)=>state.sidebar)
  const user = useSelector((state)=>state.me)
  const notifications = useSelector((state) => state.notifications);
  const dispatch = useDispatch()

  if(!user)
    return null

  const handleOnSubmit = (values) => {
    dispatch(updateMe(values))
  }

  const handleOnSelectMenu = (element) => {
    setShow(element)
  }

  const handleOnRemoveNotification = async (notificationId) => {
    await delete_notification(notificationId)
    dispatch(fetchNotifications())
  }

  return (
    <Sidebar
      as={Segment}
      animation={'overlay'}
      direction={'right'}
      visible={sidebar}
      width='very wide'
      style={{maxWidth: '100%'}}
    >
      <Grid textAlign='left' columns={1}>
        <Segment basic>
          <Button icon circular onClick={() => dispatch(toggleSidebar())}>
            <Icon name='close' />
          </Button>
        </Segment>
      </Grid>
      <Grid textAlign='center' columns={1}>
        <Grid.Column textAlign='center'>
          <Segment basic padded='very'>
            <div>
              <Image centered src={user_image} size='small' circular alt='application_seeker'/>
            </div>
            <Header as='h2'>
              {`${user.givenname} ${user.surname}`}
              <Header.Subheader>
                {user.mail}
              </Header.Subheader>
            </Header>
          </Segment>
        </Grid.Column>
      </Grid>
      <UserMenu
        show={true}
        onSelect={handleOnSelectMenu}
      />
      <Basic
        show={show === 'edit'}
        user={user}
        onSubmit={handleOnSubmit}
      />
      <Messages
        show={show === 'messages'}
        messages={notifications}
        onRemove={handleOnRemoveNotification}
      />
    </Sidebar>
  )
}

export default User;